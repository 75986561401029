import {
  Accordion,
  AccordionSummary,
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import axios from 'axios';
import { useAtomValue } from 'jotai';
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { StrongFeatureHolder } from '../../hooks/geomoby/useLiveMapLoader';
import { AUTHED_REQUEST_CONFIG } from '../../store/auth';
import { METADATA_URL, PERSISTOR_URL, TRIGGERS_URL } from '../../store/url';
import { CID, PID } from '../../store/user';
import { MICROFENCE, MICROFENCE_LAYER_ID, MICROFENCE_LAYER_LABEL } from './BeaconUtils';
import {
  PortableAssetFilter,
  GeofenceFilter,
  IBeacon,
  LayerFilter,
  MicrofenceFilter,
  MicrofenceType,
  PortableAsset,
  PortableAssetTool,
  SearchType,
  SearchTypeValue,
  ToolFilter,
  ToolType,
  CreatorFilter,
  Creator,
} from './types';
import { MicrofenceZoneTypes, SearchTypes } from './values';
import Polygon from 'ol/geom/Polygon';
import { MicrofenceData, SensedTriggeredEvent } from '../../Components/Map/Messages';
import { LineString, MultiPolygon, Point } from 'ol/geom';
import { GridRowData } from '@material-ui/data-grid';
import {
  Build,
  Clear,
  DashboardOutlined,
  FilterList,
  LocationOn,
  NearMe,
  Search,
  Shield,
  ShieldOutlined,
} from '@mui/icons-material';
import { CenteredProgress } from '../../Common/Sidebar';
import { Extent } from 'ol/extent';
import { none, some } from 'fp-ts/es6/Option';
import { transformExtent } from 'ol/proj';
import { Feature } from 'ol';
import { KNOWN_TOOLS } from '../../store/tools';
import { SelectedAsset } from './LiveMap';
import { fromNullable, toNullable, Option, isNone, isSome } from 'fp-ts/es6/Option';
import {
  AssetLocation,
  LocationDisplayType,
  LocationSearch,
  LocationSearchData,
} from './Toolbar/LocationSearch';
import { Map as OlMap } from 'ol';
import { SHOW_TOOL_FINDER } from '../../store/map';
import { lineLimitStyle, truncationStyle } from '../Map/AssetRenderer';
import { useMobile } from '../../util/useMobile';
import { FenceZone, MicrofenceZone, TRACKED_DEVICE } from './MapDefaults';
import { NameId } from './GeofenceEditor/GeofenceEditorFunctions';

export const getFenceIcon = (zone: FenceZone) => {
  return (
    <>
      {zone === FenceZone.breach && <Shield style={{ color: 'red' }} />}
      {zone === FenceZone.buffer && <ShieldOutlined style={{ color: 'orange' }} />}
      {zone === FenceZone.cleared && <DashboardOutlined style={{ color: '#B642F5' }} />}
    </>
  );
};

export const getMicroFenceIcon = (type: 'beacon' | 'device' | 'gateway' | 'smartplug') => {
  return (
    <>
      {type === 'beacon' && (
        <img
          style={{ margin: '-12px 8px -12px 0px' }}
          src="./IndoorMap/BeaconFar.svg"
          alt="Beaon Icon"
        />
      )}
      {type === 'device' && (
        <img style={{ margin: '-16px -4px -28px -2px' }} src="./IndoorMap/SmartphoneFar.svg" />
      )}
      {(type === 'gateway' || type === 'smartplug') && (
        <img style={{ margin: '-20px -66px -25px 0px' }} src="./IndoorMap/RouterFar.svg" />
      )}
    </>
  );
};

export const DataTable = ({ rows }: { rows: { name: string; value: string }[] }) => (
  <TableContainer component={Paper}>
    <Table size="small" aria-label="a dense table">
      <TableHead>
        <TableRow>
          <TableCell style={{ fontSize: '15px' }}>Property</TableCell>
          <TableCell style={{ fontSize: '15px' }} align="right">
            Values
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.length === 0 && (
          <TableRow>
            <TableCell>No properties found</TableCell>
          </TableRow>
        )}
        {rows.map(row => (
          <TableRow
            key={row.name}
            sx={{ fontSize: '15px', '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <Tooltip title={row.name}>
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
            </Tooltip>
            <Tooltip title={row.value}>
              <TableCell align="right">{row.value}</TableCell>
            </Tooltip>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

export const LiveMapSearch = ({
  layers,
  microfences,
  selectedBeacon,
  setSelectedBeacon,
  selectedDevice,
  setSelectedDevice,
  selectedGPSTracker,
  setSelectedGPSTracker,
  selectedGeofence,
  setSelectedGeofence,
  selectedMicrofence,
  setSelectedMicrofence,
  selectedTool,
  setSelectedTool,
  setToolTypes,
  setExtent,
  searchType,
  setSearchType,
  layerFilter,
  setLayerFilter,
  portableAssetFilter,
  setPortableAssetFilter,
  toolFilter,
  setToolFilter,
  geofenceFilter,
  setGeofenceFilter,
  microfenceFilter,
  setMicrofenceFilter,
  clearFilter,
  setClearFilter,
  showFilter,
  setShowFilter,
  refreshSearch,
  setRefreshSearch,
  activeAssets,
  selectedAsset,
  setSelectedAsset,
  setLocationSearchData,
  currentCenter,
  setLastKnownAssetLocation,
  replayStartDateTime,
  locationDisplay,
  setLocationDisplay,
  setNavigateTo,
  setSelectedFromMap,
  setDeselectFence,
}: {
  layers: NameId[];
  microfences: StrongFeatureHolder<Point, MicrofenceData>[];
  selectedBeacon: PortableAsset | undefined;
  setSelectedBeacon: Dispatch<PortableAsset | undefined>;
  selectedDevice: PortableAsset | undefined;
  setSelectedDevice: Dispatch<PortableAsset | undefined>;
  selectedGPSTracker: PortableAsset | undefined;
  setSelectedGPSTracker: Dispatch<PortableAsset | undefined>;
  selectedGeofence: GridRowData | undefined;
  setSelectedGeofence: Dispatch<GridRowData | undefined>;
  selectedMicrofence: GridRowData | undefined;
  setSelectedMicrofence: Dispatch<GridRowData | undefined>;
  selectedTool: PortableAssetTool | undefined;
  setSelectedTool: Dispatch<PortableAssetTool | undefined>;
  setToolTypes: Dispatch<ToolType[]>;
  setExtent: Dispatch<SetStateAction<Option<Extent>>>;
  searchType: SearchType | undefined;
  setSearchType: Dispatch<SearchType | undefined>;
  layerFilter: LayerFilter | undefined;
  setLayerFilter: Dispatch<LayerFilter | undefined>;
  portableAssetFilter: PortableAssetFilter;
  setPortableAssetFilter: Dispatch<PortableAssetFilter>;
  toolFilter: ToolFilter;
  setToolFilter: Dispatch<ToolFilter>;
  geofenceFilter: GeofenceFilter;
  setGeofenceFilter: Dispatch<GeofenceFilter>;
  microfenceFilter: MicrofenceFilter | undefined;
  setMicrofenceFilter: Dispatch<MicrofenceFilter | undefined>;
  clearFilter: boolean;
  setClearFilter: Dispatch<boolean>;
  showFilter: boolean;
  setShowFilter: Dispatch<boolean>;
  refreshSearch: boolean;
  setRefreshSearch: Dispatch<boolean>;
  activeAssets: SelectedAsset[];
  selectedAsset: Option<SelectedAsset>;
  setSelectedAsset: Dispatch<Option<SelectedAsset>>;
  setLocationSearchData: Dispatch<SetStateAction<LocationSearchData | undefined>>;
  currentCenter: number[] | undefined;
  setLastKnownAssetLocation: Dispatch<SetStateAction<AssetLocation | undefined>>;
  replayStartDateTime: Date | null;
  locationDisplay: LocationDisplayType | undefined;
  setLocationDisplay: Dispatch<SetStateAction<LocationDisplayType | undefined>>;
  setNavigateTo: Dispatch<SetStateAction<string | null>>;
  setSelectedFromMap: Dispatch<SetStateAction<boolean>>;
  setDeselectFence: Dispatch<SetStateAction<boolean>>;
}) => {
  const cid = useAtomValue(CID);
  const pid = useAtomValue(PID);
  const authedConfig = useAtomValue(AUTHED_REQUEST_CONFIG);
  const metadataUrl = useAtomValue(METADATA_URL);
  const persistorUrl = useAtomValue(PERSISTOR_URL);
  const triggersUrl = useAtomValue(TRIGGERS_URL);
  const knownTools = useAtomValue(KNOWN_TOOLS);
  const showToolFinder = useAtomValue(SHOW_TOOL_FINDER);

  const [availableBeacons, setAvailableBeacons] = useState<PortableAsset[]>([]);
  const [availableDevices, setAvailableDevices] = useState<PortableAsset[]>([]);
  const [availableGeofences, setAvailableGeofences] = useState<GridRowData[]>([]);
  const [availableGpsTrackers, setAvailableGpsTrackers] = useState<PortableAsset[]>([]);
  const [availableMicrofences, setAvailableMicrofences] =
    useState<StrongFeatureHolder<Point, MicrofenceData>[]>(microfences);
  const [availableTools, setAvailableTools] = useState<PortableAssetTool[]>([]);
  const [count, setCount] = useState<number>(0);
  const [displayCount, setDisplayCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const allBeaconsRef = useRef<(PortableAsset & { uuid: string; type: string })[]>([]);
  const isMobile = useMobile();
  const pageRef = useRef<number>(1);

  const isLine = useMemo(
    () =>
      selectedGeofence?.type?.toLowerCase()?.includes('line') ||
      selectedGeofence?.points?.type?.toLowerCase()?.includes('line'),
    [selectedGeofence],
  );
  const isPolygon = useMemo(
    () =>
      selectedGeofence?.type?.toLowerCase() === 'polygon' ||
      selectedGeofence?.points?.type?.toLowerCase() === 'polygon',
    [selectedGeofence],
  );
  const isMultipolygon = useMemo(
    () =>
      selectedGeofence?.type?.toLowerCase()?.includes('multipolygon') ||
      selectedGeofence?.points?.type?.toLowerCase()?.includes('multipolygon'),
    [selectedGeofence],
  );

  const getGeofence = useCallback(
    async (fence: GridRowData) => {
      setSelectedMicrofence(undefined);
      pageRef.current;
      const geofence = (
        await axios.get<{
          id: string;
          name: string;
          points: { coordinates: Extent };
          geomobyProperties: Record<string, string>;
        }>(
          `${triggersUrl}/${cid}/${pid}/geofences/${fence.layerId}/${fence.type}/${fence.id}`,
          authedConfig,
        )
      ).data;

      if (!geofence) return;
      const newFence =
        fence.type === 'polygon'
          ? new Polygon(geofence.points.coordinates)
          : fence.type === 'multipolygon'
          ? new MultiPolygon(geofence.points.coordinates)
          : new LineString(geofence.points.coordinates);

      const extent = transformExtent(newFence.getExtent(), 'EPSG:4326', 'EPSG:3857');
      setExtent(some(extent));
    },
    [cid, pid, triggersUrl, authedConfig, setExtent, setSelectedMicrofence],
  );

  const getToolTypes = useCallback(async () => {
    setToolTypes(
      (
        await axios.get<ToolType[]>(
          `${metadataUrl}/${cid}/${pid}/portableasset/tool/all`,
          authedConfig,
        )
      ).data,
    );
  }, [cid, pid, metadataUrl, authedConfig, setToolTypes]);

  const paginateCreators = useCallback(
    async (filter: CreatorFilter) => {
      setCount(0);
      if (pageRef.current === 1) {
        setIsLoading(true);
        setDisplayCount(0);
      }

      const allowedCreatorTypes = ['DEVICES', 'GPSTRACKERS'] as const;
      const creatorType = allowedCreatorTypes.find(c => c === filter.creatorType) ?? 'DEVICES';

      const params = Object.entries({
        ...filter,
        creatorType,
      }).map(([key, val]) => [key, typeof val === 'object' ? val.id : val.toString()]);

      const filterString = new URLSearchParams(params).toString();

      const { creators, count } = (
        await axios.get<{ creators: Creator[]; count: number }>(
          `${persistorUrl}/${cid}/${pid}/analytics/creators/paginate/${pageRef.current}?${filterString}`,
          authedConfig,
        )
      ).data;
      if (filter.creatorType === 'DEVICES') {
        if (pageRef.current === 1) {
          setAvailableDevices(
            creators.map(creator => {
              return {
                id: creator.identifiers?.deviceId,
                label: creator.label,
                type: 'device',
              } as PortableAsset;
            }),
          );
        } else {
          const nextPage = [...availableDevices, ...creators];
          setAvailableDevices(
            nextPage.map((asset: PortableAsset | Creator) => {
              if ('identifiers' in asset) {
                return {
                  id: asset.identifiers?.deviceId,
                  label: asset.label,
                  type: 'device',
                } as PortableAsset;
              }
              return asset as PortableAsset;
            }),
          );
        }
      } else if (filter.creatorType === 'GPSTRACKERS') {
        if (pageRef.current === 1) {
          setAvailableGpsTrackers(
            creators.map(creator => {
              return {
                id: creator.identifiers?.gpsTrackerId,
                label: creator.label,
                type: 'gpstracker',
              } as PortableAsset;
            }),
          );
        } else {
          const nextPage = [...availableGpsTrackers, ...creators];
          setAvailableGpsTrackers(
            nextPage.map((asset: PortableAsset | Creator) => {
              if ('identifiers' in asset) {
                return {
                  id: asset.identifiers?.gpsTrackerId,
                  label: asset.label,
                  type: 'gpstracker',
                } as PortableAsset;
              }
              return asset as PortableAsset;
            }),
          );
        }
      }

      setCount(count);
      setDisplayCount(count);
      setIsLoading(false);
    },
    [cid, pid, persistorUrl, authedConfig, availableDevices, availableGpsTrackers],
  );

  const paginatePortableAssets = useCallback(
    async (filter: PortableAssetFilter) => {
      setCount(0);
      if (pageRef.current === 1) {
        setIsLoading(true);
        setDisplayCount(0);
      }

      let filters = `?type=beacon&perPage=${filter.perPage}`;
      Object.entries(filter).map(([key, value]) => {
        if (key !== undefined && value !== undefined && key !== 'type' && key !== 'perPage') {
          filters = filters.concat(
            `&${key}=${typeof value === 'object' ? value.id : value.toString()}`,
          );
        }
      });
      const { portableAssets, count } = (
        await axios.get<{ portableAssets: PortableAsset[]; count: number }>(
          `${metadataUrl}/${cid}/${pid}/portableAsset/paginate/${pageRef.current}${filters}`,
          authedConfig,
        )
      ).data;
      if (filter.type === 'BEACONS') {
        if (pageRef.current === 1) {
          setAvailableBeacons(portableAssets);
        } else {
          const nextPage = [...availableBeacons, ...portableAssets];
          setAvailableBeacons(nextPage);
        }
      }
      setCount(count);
      setDisplayCount(count);
      setIsLoading(false);
    },
    [cid, pid, metadataUrl, authedConfig, availableBeacons],
  );

  const paginateGeofences = useCallback(
    async (filter: GeofenceFilter) => {
      if (layers.length === 0) {
        setAvailableGeofences([]);
        setCount(0);
        setDisplayCount(0);
        return;
      }

      const filterAllLayers = filter?.layerId === 'ALL';
      if (filterAllLayers) {
        filter.layerId = undefined;
      }
      setCount(0);
      if (pageRef.current === 1) {
        setIsLoading(true);
        setDisplayCount(0);
      }

      let filters = `?perPage=${filter.perPage}`;
      Object.entries(filter).map(([key, value]) => {
        if (key !== undefined && value !== undefined && key !== 'perPage') {
          filters = filters.concat(
            `&${key}=${typeof value === 'object' ? value.id : value.toString()}`,
          );
        }
      });

      const { geofences, count } = (
        await axios.get<{ geofences: GridRowData[]; count: number }>(
          `${triggersUrl}/${cid}/${pid}/geofences/paginate/${pageRef.current}${filters}`,
          authedConfig,
        )
      ).data;

      if (pageRef.current === 1) {
        setAvailableGeofences(geofences);
      } else {
        const nextPage = [...availableGeofences, ...geofences];
        setAvailableGeofences(nextPage);
      }
      setCount(count);
      setDisplayCount(count);
      setIsLoading(false);
    },
    [cid, pid, triggersUrl, authedConfig, availableGeofences, layers],
  );

  const paginateTools = useCallback(
    async (filter: ToolFilter) => {
      setCount(0);
      if (pageRef.current === 1) {
        setIsLoading(true);
        setDisplayCount(0);
      }

      let filters = `?perPage=${filter.perPage}`;
      Object.entries(filter).map(([key, value]) => {
        if (key !== undefined && value !== undefined && key !== 'perPage') {
          filters = filters.concat(
            `&${key}=${typeof value === 'object' ? value.id : value.toString()}`,
          );
        }
      });

      const { tools, count } = (
        await axios.get<{ tools: PortableAssetTool[]; count: number }>(
          `${metadataUrl}/${cid}/${pid}/portableasset/tool/paginate/${pageRef.current}${filters}`,
          authedConfig,
        )
      ).data;

      if (pageRef.current === 1) {
        setAvailableTools(tools);
      } else {
        const nextPage = [...availableTools, ...tools];
        setAvailableTools(nextPage);
      }

      setCount(count);
      setDisplayCount(count);
      setIsLoading(false);
    },
    [cid, pid, metadataUrl, authedConfig, availableTools],
  );

  const getLastKnownLocation = useCallback(
    async (creatorLabel: string) => {
      const toDate = replayStartDateTime ?? new Date();
      const fromDate = new Date(toDate);
      fromDate.setDate(fromDate.getDate() - 7);

      const data = (
        await axios.get<{ point: { x: number; y: number }; timestamp: string }>(
          `${persistorUrl}/${cid}/${pid}/analytics/last-known-coordinates`,
          {
            params: {
              fromIso8601: fromDate.toISOString(),
              toIso8601: toDate.toISOString(),
              creatorLabel,
              type: 'DEVICE',
            },
            ...authedConfig,
          },
        )
      ).data;
      if (data) {
        setLastKnownAssetLocation({
          lat: data.point?.y,
          lon: data.point?.x,
          timestamp: data.timestamp,
        });
      }
    },
    [persistorUrl, cid, pid, authedConfig, setLastKnownAssetLocation, replayStartDateTime],
  );

  const refreshFilter = useCallback(() => {
    setAvailableGeofences([]);
    setAvailableMicrofences(microfences);
    setCount(0);
    setDisplayCount(0);
    setPortableAssetFilter({ perPage: 50 });
    setGeofenceFilter({ perPage: 50 });
    setToolFilter({ perPage: 50 });
    setMicrofenceFilter(undefined);
    setSelectedAsset(none);
    setLastKnownAssetLocation(undefined);
    pageRef.current = 1;

    if (searchType?.id === 'GEOFENCES') {
      paginateGeofences({ perPage: 50, layerId: layerFilter?.layer?.id } as GeofenceFilter);
    }
    if (searchType?.id === 'BEACONS') {
      paginatePortableAssets({ perPage: 50, type: searchType?.id });
    }
    if (searchType?.id === 'DEVICES' || searchType?.id === 'GPSTRACKERS') {
      paginateCreators({ perPage: 50, creatorType: searchType.id });
    }
    if (searchType?.id === 'TOOLS') {
      paginateTools({ perPage: 50 });
      getToolTypes();
    }
  }, [
    layerFilter,
    paginateGeofences,
    paginatePortableAssets,
    paginateCreators,
    paginateTools,
    getToolTypes,
    setPortableAssetFilter,
    setGeofenceFilter,
    microfences,
    setMicrofenceFilter,
    searchType,
    setToolFilter,
    setSelectedAsset,
    setLastKnownAssetLocation,
  ]);

  const clearAll = () => {
    setAvailableGeofences([]);
    setAvailableMicrofences(microfences);
    setAvailableBeacons([]);
    setAvailableDevices([]);
    setAvailableGpsTrackers([]);
    setCount(0);
    setDisplayCount(0);
    setGeofenceFilter({ perPage: 50 });
    setMicrofenceFilter(undefined);
    setLayerFilter(undefined);
    pageRef.current = 1;
    setSearchType(undefined);
    setSelectedAsset(none);
    setLastKnownAssetLocation(undefined);
  };

  const selectedElement = (): boolean =>
    !!selectedBeacon ||
    !!selectedDevice ||
    !!selectedGPSTracker ||
    !!selectedGeofence ||
    !!selectedMicrofence ||
    !!selectedTool;

  const findAvailableMicrofence = (
    asset: SelectedAsset,
  ): StrongFeatureHolder<Point, MicrofenceData> | undefined => {
    return microfences.find(microfence => {
      const assetId = microfence.feature.get('assetId');
      if (!assetId) return undefined;
      return (
        (assetId.gatewayId !== undefined && assetId.gatewayId === asset.id?.gatewayId) ||
        (assetId.uuid !== undefined &&
          assetId.major !== undefined &&
          assetId.minor !== undefined &&
          assetId.uuid === asset.id?.uuid &&
          assetId.major === asset.id?.major &&
          assetId.minor === asset.id?.minor) ||
        (assetId.deviceId !== undefined && assetId.deviceId === asset.id?.deviceId) ||
        (assetId.smartplugId !== undefined && assetId.smartplugId === asset.id?.smartplugId)
      );
    });
  };

  const findKnownTool = (
    identifiers: Record<string, unknown> | undefined,
  ): PortableAssetTool | undefined => {
    if (!identifiers) return;
    return knownTools.find(
      tool => tool.type === 'tool' && String(tool.id) === identifiers?.beaconId,
    );
  };

  const findActiveAsset = (id: string): SelectedAsset | undefined => {
    return activeAssets.find(
      active => active.id?.beaconId !== undefined && active.id?.beaconId === id,
    );
  };

  useEffect(() => {
    if (!refreshSearch) return;

    if (searchType?.id === 'GEOFENCES') {
      pageRef.current = 1;
      paginateGeofences({ ...geofenceFilter, layerId: layerFilter?.layer?.id } as GeofenceFilter);
    }

    // TODO: This will have to do for microfence searching for now. At least until we have an end-point that does it - LTP-478.
    if (searchType?.id === 'MICROFENCES') {
      let updatedMicrofences = microfences;
      Object.entries(microfenceFilter ?? {}).map(([key, value]) => {
        if (
          !(
            key === 'orderBy' ||
            key === 'order' ||
            key === 'microfenceProperties' ||
            typeof value === 'string'
          )
        ) {
          updatedMicrofences = updatedMicrofences.filter(
            microfence => microfence.feature.get(key) === (value as MicrofenceType).id,
          );
        }
        if (key === 'searchName') {
          updatedMicrofences = updatedMicrofences.filter(microfence =>
            microfence.feature.get('name').includes(value),
          );
        }
        if (key === 'searchId') {
          updatedMicrofences = updatedMicrofences.filter(microfence => {
            return (
              (microfence.feature.get('type') === 'beacon' &&
                microfence.feature.get('assetId').uuid.includes(value)) ||
              (microfence.feature.get('type') === 'gateway' &&
                microfence.feature.get('assetId').gatewayId.includes(value)) ||
              (microfence.feature.get('type') === 'device' &&
                microfence.feature.get('assetId').deviceId.includes(value)) ||
              (microfence.feature.get('type') === 'smartplug' &&
                microfence.feature.get('assetId').smartplugId.includes(value))
            );
          });
        }
        if (key === 'microfenceProperties') {
          updatedMicrofences = updatedMicrofences.filter(microfence => {
            const found = Object.entries(microfence.feature.get('geomobyProperties')).find(
              ([k, v]) => {
                if (microfenceFilter?.microfenceProperties?.operator?.id === 'EQUAL_TO') {
                  return (
                    k === microfenceFilter?.microfenceProperties?.type?.value &&
                    Number(v) === microfenceFilter?.microfenceProperties?.value
                  );
                } else if (
                  microfenceFilter?.microfenceProperties?.operator?.id === 'GREATER_THAN'
                ) {
                  return (
                    k === microfenceFilter?.microfenceProperties?.type?.value &&
                    Number(v) > microfenceFilter?.microfenceProperties?.value
                  );
                } else {
                  return (
                    k === microfenceFilter?.microfenceProperties?.type?.value &&
                    Number(v) < microfenceFilter?.microfenceProperties?.value
                  );
                }
              },
            );
            if (!found) return;
            return microfence;
          });
        }
      });

      updatedMicrofences =
        microfenceFilter?.order?.id === 'DESC'
          ? updatedMicrofences.sort((a, b) =>
              b.feature
                .get(String(microfenceFilter?.orderBy?.id ?? 'name'))
                .localeCompare(a.feature.get(String(microfenceFilter?.orderBy?.id ?? 'name'))),
            )
          : updatedMicrofences.sort((a, b) =>
              a.feature
                .get(String(microfenceFilter?.orderBy?.id ?? 'name'))
                .localeCompare(b.feature.get(String(microfenceFilter?.orderBy?.id ?? 'name'))),
            );

      setAvailableMicrofences(updatedMicrofences);
    }

    if (searchType?.id === 'BEACONS') {
      pageRef.current = 1;
      paginatePortableAssets({
        ...portableAssetFilter,
        type: searchType?.id,
      });
    }
    if (searchType?.id === 'DEVICES' || searchType?.id === 'GPSTRACKERS') {
      pageRef.current = 1;
      paginateCreators({
        ...portableAssetFilter,
        creatorType: searchType.id,
      });
    }

    if (searchType?.id === 'TOOLS') {
      pageRef.current = 1;
      paginateTools(toolFilter);
      getToolTypes();
    }

    setRefreshSearch(false);
    setShowFilter(false);
  }, [
    refreshSearch,
    paginateGeofences,
    paginatePortableAssets,
    paginateCreators,
    paginateTools,
    getToolTypes,
    searchType,
    geofenceFilter,
    layerFilter,
    setRefreshSearch,
    setShowFilter,
    microfenceFilter,
    microfences,
    refreshFilter,
    portableAssetFilter,
    toolFilter,
  ]);

  useEffect(() => {
    if (clearFilter) {
      refreshFilter();
      setClearFilter(false);
    }
  }, [clearFilter, refreshFilter, setClearFilter]);

  return (
    <>
      <Grid
        style={{
          contentVisibility: showFilter ? 'hidden' : 'visible',
        }}
      >
        {selectedElement() && (
          <Button
            style={{
              marginLeft: '-7px',
            }}
            onClick={() => {
              setSelectedBeacon(undefined);
              setSelectedDevice(undefined);
              setSelectedGPSTracker(undefined);
              setSelectedGeofence(undefined);
              setSelectedMicrofence(undefined);
              setSelectedTool(undefined);
              setSelectedAsset(none);
              setDeselectFence(true);
            }}
          >
            Back
          </Button>
        )}

        {/* Search type  */}
        {!selectedElement() && (
          <FormControl
            fullWidth
            style={{
              width: '98%',
              marginBottom: '20px',
            }}
          >
            <InputLabel id="search-option">Search For</InputLabel>
            <Select
              fullWidth
              labelId="search-option"
              id="search-option"
              value={searchType?.value ?? ''}
              label="Search For"
              onChange={e => {
                const search = SearchTypes.find(
                  l => l.value === (e.target.value as SearchTypeValue),
                );
                clearAll();
                setSearchType(search);

                if (search?.id === 'GEOFENCES') {
                  setLayerFilter({
                    ...layerFilter,
                    layer: { name: 'All Groups', id: 'ALL' },
                  });
                  pageRef.current = 1;
                  paginateGeofences({ ...geofenceFilter, layerId: 'ALL' } as GeofenceFilter);
                }
                if (search?.id === 'MICROFENCES') {
                  setLayerFilter({
                    ...layerFilter,
                    layer: { name: 'Microfences', id: MICROFENCE_LAYER_ID },
                  });
                }
                if (search?.id === 'BEACONS') {
                  setPortableAssetFilter({
                    perPage: 50,
                    type: search?.id,
                  });
                  paginatePortableAssets({
                    perPage: 50,
                    type: search?.id,
                  });
                }
                if (search?.id === 'DEVICES' || search?.id === 'GPSTRACKERS') {
                  paginateCreators({
                    perPage: 50,
                    creatorType: search.id,
                  });
                }
                if (search?.id === 'TOOLS') {
                  setToolFilter({ perPage: 50 });
                  paginateTools({ perPage: 50 });
                  getToolTypes();
                }
              }}
            >
              {SearchTypes.map(search => (
                <MenuItem
                  key={search.id}
                  value={search.value}
                  disabled={search.disabled || (!showToolFinder && search.id === 'TOOLS')}
                >
                  <Tooltip title={search.value}>
                    <Typography
                      style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        width: 'calc(100% - 30px)',
                      }}
                    >
                      {search.value}
                    </Typography>
                  </Tooltip>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        {/* Layers  */}
        {!selectedElement() && searchType?.id === 'GEOFENCES' && (
          <FormControl
            fullWidth
            style={{
              width: '98%',
            }}
          >
            <InputLabel id="search-group-option">Select a group</InputLabel>
            <Select
              fullWidth
              labelId="search-group-option"
              id="search-group-option"
              disabled={isLoading}
              value={
                (layerFilter?.layer === undefined && availableGeofences.length > 0
                  ? 'All Groups'
                  : layerFilter?.layer?.name) ?? ''
              }
              label="Select a group"
              onChange={e => {
                const layer = [...[{ name: 'All Groups', id: 'ALL' }], ...layers].find(
                  l => l.name === e.target.value,
                );
                setLayerFilter({
                  ...layerFilter,
                  layer,
                });
                pageRef.current = 1;
                paginateGeofences({ ...geofenceFilter, layerId: layer?.id } as GeofenceFilter);
              }}
            >
              {[...[{ name: 'All Groups', id: 'ALL' }], ...layers].map(layer => (
                <MenuItem key={layer.id} value={layer.name}>
                  <Tooltip title={layer.name}>
                    <Typography
                      style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        width: 'calc(100% - 30px)',
                      }}
                    >
                      {layer.name}
                    </Typography>
                  </Tooltip>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        {/* Filter button and count  */}
        {searchType &&
          searchType?.id !== 'LOCATIONS' &&
          searchType?.id !== 'ACTIVE_ASSETS' &&
          !selectedElement() &&
          !isLoading && (
            <Grid
              container
              direction="column"
              style={{
                display: 'grid',
                gridTemplateColumns: '50% 50%',
              }}
            >
              <Grid item>
                <Button onClick={() => setShowFilter(true)}>
                  <span style={{ fontSize: '10px' }}>Filter</span>
                  <FilterList />
                </Button>
                <Button onClick={() => setClearFilter(true)}>
                  <span style={{ fontSize: '10px' }}>Clear</span>
                  <Clear />
                </Button>
              </Grid>

              <Grid
                item
                style={{
                  margin: '8px 10px 0px 0px',
                  justifySelf: 'end',
                }}
              >
                {(searchType?.id === 'GEOFENCES' ||
                  searchType?.id === 'BEACONS' ||
                  searchType?.id === 'DEVICES' ||
                  searchType?.id === 'GPSTRACKERS' ||
                  searchType?.id === 'TOOLS') && (
                  <Typography>{`${displayCount} ${
                    Number(displayCount) === 1 ? 'result' : 'results'
                  }`}</Typography>
                )}
                {searchType?.id === 'MICROFENCES' && (
                  <Typography>{`${availableMicrofences.length} ${
                    Number(availableMicrofences.length) === 1 ? 'result' : 'results'
                  }`}</Typography>
                )}
              </Grid>
            </Grid>
          )}

        {/* Geofences, Microfences, Assets */}
        {!selectedElement() && (
          <Grid
            container
            direction={'row'}
            style={{
              marginTop: '10px',
              height: 'fit-content',
              maxHeight: '620px',
              overflowY: 'auto',
            }}
            onScroll={e => {
              const target = e.target as HTMLTextAreaElement;
              if (isLoading) return;

              const scrolledEnough = target.scrollTop % target.clientHeight < 5;
              const canLoadCreators =
                searchType?.id === 'DEVICES' || searchType?.id === 'GPSTRACKERS';

              const loadGeofences =
                searchType?.id === 'GEOFENCES' &&
                scrolledEnough &&
                count > availableGeofences.length;
              if (loadGeofences) {
                pageRef.current += 1;
                paginateGeofences({
                  ...geofenceFilter,
                  layerId: layerFilter?.layer?.id,
                  perPage: 50,
                } as GeofenceFilter);
              }

              const loadBeacons =
                scrolledEnough && searchType?.id === 'BEACONS' && count > availableBeacons.length;
              if (loadBeacons) {
                pageRef.current += 1;
                paginatePortableAssets({
                  ...portableAssetFilter,
                  perPage: 50,
                  type: searchType?.id,
                } as PortableAssetFilter);
              }

              const loadCreators =
                scrolledEnough && canLoadCreators && count > availableBeacons.length;
              if (loadCreators) {
                pageRef.current += 1;
                paginateCreators({
                  ...portableAssetFilter,
                  perPage: 50,
                  creatorType: searchType.id,
                } as CreatorFilter);
              }

              const loadTools =
                scrolledEnough && searchType?.id === 'TOOLS' && count > availableTools.length;
              if (loadTools) {
                pageRef.current += 1;
                paginateTools({
                  ...toolFilter,
                  perPage: 50,
                } as ToolFilter);
              }
            }}
          >
            {isLoading && (
              <Grid
                container
                justifyContent={'center'}
                style={{
                  marginTop: '100px',
                }}
              >
                <CenteredProgress />
              </Grid>
            )}

            {/*Geofences */}
            {!isLoading &&
              searchType?.id === 'GEOFENCES' &&
              availableGeofences.map(fence => {
                return (
                  <Accordion id={fence.id} key={fence.id} expanded={false} style={{ width: '98%' }}>
                    <AccordionSummary
                      onClick={() => {
                        fence.selected = true;
                        setSelectedFromMap(false);
                        setSelectedGeofence(fence);
                        getGeofence(fence);
                      }}
                      sx={{
                        '& .MuiAccordionSummary-content': {
                          width: '90%',
                        },
                      }}
                    >
                      {getFenceIcon(fence.zone)}
                      <Tooltip
                        title={`${fence.name}${
                          !fence.zone || fence.zone === FenceZone.none
                            ? ''
                            : ' (' + fence.zone.toUpperCase() + ')'
                        }`}
                      >
                        <Typography
                          style={{
                            width: '100%',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {fence.name}
                        </Typography>
                      </Tooltip>
                    </AccordionSummary>
                  </Accordion>
                );
              })}

            {/* Microfences */}
            {!isLoading &&
              searchType?.id === 'MICROFENCES' &&
              availableMicrofences.map(microfence => {
                return (
                  <Accordion
                    id={microfence.feature.get('id')}
                    key={microfence.feature.get('id')}
                    style={{ width: '98%' }}
                  >
                    <AccordionSummary
                      onClick={() => {
                        const coords = microfence.feature.get('point')?.coordinates;
                        const extent = transformExtent(
                          [...coords, ...coords],
                          'EPSG:4326',
                          'EPSG:3857',
                        );
                        microfence.feature.set('selected', true);
                        setSelectedFromMap(false);
                        setSelectedMicrofence(microfence.feature.getProperties());
                        const activeMicrofence = activeAssets.find(
                          active =>
                            (active.prefix === MICROFENCE &&
                              active.id?.gatewayId !== undefined &&
                              active.id?.gatewayId ===
                                microfence.feature.get('assetId')?.gatewayId) ||
                            (active.id?.uuid !== undefined &&
                              active.id?.major !== undefined &&
                              active.id?.minor !== undefined &&
                              active.id?.uuid === microfence.feature.get('assetId')?.uuid &&
                              active.id?.major === microfence.feature.get('assetId')?.major &&
                              active.id?.minor === microfence.feature.get('assetId')?.minor) ||
                            (active.id?.deviceId !== undefined &&
                              active.id?.deviceId === microfence.feature.get('assetId')?.deviceId),
                        );
                        if (!activeMicrofence) return;
                        setSelectedAsset(fromNullable(activeMicrofence));
                        if (coords) {
                          setExtent(some(extent));
                        }
                      }}
                      sx={{
                        '& .MuiAccordionSummary-content': {
                          width: '90%',
                        },
                      }}
                    >
                      {getMicroFenceIcon(microfence.feature.get('microfenceType'))}
                      <Tooltip title={microfence.feature.get('name')}>
                        <Typography
                          style={{
                            width: '100%',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {microfence.feature.get('name')}
                        </Typography>
                      </Tooltip>
                    </AccordionSummary>
                  </Accordion>
                );
              })}

            {/* Active Assets */}
            {!isLoading &&
              searchType?.id === 'ACTIVE_ASSETS' &&
              [
                ...activeAssets
                  .sort((a, b) => (a.label ?? 'NO LABEL').localeCompare(b.label ?? 'NO LABEL'))
                  .filter(asset => !asset.id?.localised),
              ].map((asset, index) => {
                return (
                  <Accordion
                    id={String(asset.id)}
                    key={index}
                    expanded={false}
                    style={{ width: '98%' }}
                  >
                    <AccordionSummary
                      onClick={() => {
                        if (asset.prefix === TRACKED_DEVICE) {
                          if (asset.id?.deviceId) {
                            setSelectedAsset(fromNullable(asset));
                            setSelectedDevice({
                              id: asset.id?.deviceId as string,
                              label:
                                asset.label ??
                                availableDevices.find(d => d.id === asset.id?.deviceId)?.label ??
                                '',
                              type: 'device',
                            });
                          } else if (asset.id?.gpsTrackerId) {
                            setSelectedAsset(fromNullable(asset));
                            setSelectedGPSTracker({
                              id: asset.id?.gpsTrackerId as string,
                              label:
                                asset.label ??
                                availableGpsTrackers.find(d => d.id === asset.id?.gpsTrackerId)
                                  ?.label ??
                                '',
                              type: 'gpsTracker',
                            });
                          } else if (asset.id?.beaconId) {
                            setSelectedAsset(fromNullable(asset));
                            const foundTool = findKnownTool(asset.id);
                            if (foundTool) {
                              setSelectedTool(foundTool as PortableAssetTool);
                            } else {
                              setSelectedBeacon({
                                id: asset.id.beaconId as string,
                                label:
                                  asset.label ??
                                  availableBeacons.find(b => b.id === asset.id?.beaconId)?.label ??
                                  '',
                                type: 'beacon',
                              });
                            }
                          }
                        } else if (asset.prefix === MICROFENCE) {
                          setSelectedAsset(fromNullable(asset));
                          const activeMicrofence = findAvailableMicrofence(asset);
                          if (activeMicrofence) {
                            activeMicrofence.feature.set('selected', true);
                            const coords = activeMicrofence.feature.get('point')?.coordinates;
                            const extent = transformExtent(
                              [...coords, ...coords],
                              'EPSG:4326',
                              'EPSG:3857',
                            );
                            setSelectedMicrofence(activeMicrofence.feature.getProperties());
                            if (coords) {
                              setExtent(some(extent));
                            }
                          }
                        }
                      }}
                      sx={{
                        '& .MuiAccordionSummary-content': {
                          width: '90%',
                        },
                      }}
                    >
                      {asset.prefix === MICROFENCE &&
                        getMicroFenceIcon(
                          findAvailableMicrofence(asset)?.feature?.get('microfenceType'),
                        )}
                      <Tooltip title={asset.label || 'NO LABEL'}>
                        <Typography
                          style={{
                            width: '100%',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {asset.prefix !== MICROFENCE && asset.id?.deviceId && '[DEVICE] '}
                          {asset.id?.gpsTrackerId && '[TRACKER] '}
                          {asset.prefix !== MICROFENCE && !findKnownTool(asset.id) && '[BEACON] '}
                          {asset.prefix !== MICROFENCE && findKnownTool(asset.id) && '[TOOL] '}
                          <span
                            style={{
                              color: asset.label ? '#fff' : '#924439',
                              ...truncationStyle,
                            }}
                          >
                            {asset.label || 'NO LABEL'}
                          </span>
                        </Typography>
                      </Tooltip>
                    </AccordionSummary>
                  </Accordion>
                );
              })}

            {/* Beacons */}
            {!isLoading &&
              searchType?.id === 'BEACONS' &&
              availableBeacons.map((beacon, index) => {
                return (
                  <Accordion
                    id={String(beacon.id)}
                    key={index}
                    expanded={false}
                    style={{ width: '98%' }}
                  >
                    <AccordionSummary
                      onClick={() => {
                        const activeBeacon = findActiveAsset(beacon.id);
                        const foundTool = knownTools.find(t => t.id === beacon.id);
                        if (activeBeacon) {
                          if (foundTool) {
                            setSelectedAsset(
                              fromNullable({
                                id: activeBeacon.id,
                                label: beacon.label,
                              }),
                            );
                            setSelectedBeacon(beacon);
                          } else {
                            setSelectedAsset(fromNullable(activeBeacon));
                            setSelectedBeacon(beacon);
                          }
                        } else {
                          setSelectedBeacon(beacon);
                        }
                      }}
                      sx={{
                        '& .MuiAccordionSummary-content': {
                          width: '90%',
                        },
                      }}
                    >
                      <Grid
                        container
                        direction="row"
                        style={
                          findActiveAsset(beacon.id)
                            ? {
                                display: 'grid',
                                gridTemplateColumns: '80% 20%',
                              }
                            : {
                                width: 'inherit',
                              }
                        }
                      >
                        <Tooltip title={beacon.label || beacon.id}>
                          <Typography
                            style={{
                              width: '100%',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            {beacon.label && <span style={truncationStyle}>{beacon.label}</span>}
                            {!beacon.label && (
                              <span>
                                <span
                                  style={{
                                    color: '#924439',
                                  }}
                                >
                                  NO LABEL
                                </span>
                                <span style={truncationStyle}>{` (${beacon.id})`}</span>
                              </span>
                            )}
                          </Typography>
                        </Tooltip>
                        {findActiveAsset(beacon.id) && (
                          <Typography
                            style={{
                              width: '100%',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            <span
                              style={{
                                marginLeft: '10px',
                                color: '#4CB8C4',
                                ...truncationStyle,
                              }}
                            >
                              ACTIVE
                            </span>
                          </Typography>
                        )}
                      </Grid>
                    </AccordionSummary>
                  </Accordion>
                );
              })}

            {/* Devices */}
            {!isLoading &&
              searchType?.id === 'DEVICES' &&
              availableDevices.map((device, index) => {
                return (
                  <Accordion
                    id={String(device.id)}
                    key={index}
                    expanded={false}
                    style={{ width: '98%' }}
                  >
                    <AccordionSummary
                      onClick={() => {
                        setSelectedDevice(device);
                        setLastKnownAssetLocation(undefined);
                        const activeDevice = activeAssets.find(
                          active =>
                            active.id?.deviceId !== undefined && active.id?.deviceId === device.id,
                        );
                        if (activeDevice) {
                          setSelectedAsset(fromNullable(activeDevice));
                        } else if (device.label) {
                          getLastKnownLocation(device.label);
                        }
                      }}
                      sx={{
                        '& .MuiAccordionSummary-content': {
                          width: '90%',
                        },
                      }}
                    >
                      <Grid
                        container
                        direction="row"
                        style={
                          activeAssets.find(
                            active =>
                              active.id?.deviceId !== undefined &&
                              active.id?.deviceId === device.id,
                          )
                            ? {
                                display: 'grid',
                                gridTemplateColumns: '80% 20%',
                              }
                            : {}
                        }
                      >
                        <Tooltip title={device.label || device.id}>
                          <Typography
                            style={{
                              width: '100%',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            {device.label && <span style={truncationStyle}>{device.label}</span>}
                            {!device.label && (
                              <span>
                                <span
                                  style={{
                                    color: '#924439',
                                  }}
                                >
                                  NO LABEL
                                </span>
                                <span style={truncationStyle}>{` (${device.id})`}</span>
                              </span>
                            )}
                          </Typography>
                        </Tooltip>
                        {activeAssets.find(
                          active =>
                            active.id?.deviceId !== undefined && active.id?.deviceId === device.id,
                        ) && (
                          <Typography
                            style={{
                              width: '100%',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            <span
                              style={{
                                marginLeft: '10px',
                                color: '#4CB8C4',
                                ...truncationStyle,
                              }}
                            >
                              ACTIVE
                            </span>
                          </Typography>
                        )}
                      </Grid>
                    </AccordionSummary>
                  </Accordion>
                );
              })}

            {/* GPS Tracker */}
            {!isLoading &&
              searchType?.id === 'GPSTRACKERS' &&
              availableGpsTrackers.map((gpsTracker, index) => {
                return (
                  <Accordion
                    id={String(gpsTracker.id)}
                    key={index}
                    expanded={false}
                    style={{ width: '98%' }}
                  >
                    <AccordionSummary
                      onClick={() => {
                        setSelectedGPSTracker(gpsTracker);
                        setLastKnownAssetLocation(undefined);
                        const activeGPSTracker = activeAssets.find(
                          active =>
                            active.id?.gpsTrackerId !== undefined &&
                            active.id?.gpsTrackerId === gpsTracker.id,
                        );
                        if (activeGPSTracker) {
                          setSelectedAsset(fromNullable(activeGPSTracker));
                        } else if (gpsTracker.label) {
                          getLastKnownLocation(gpsTracker.label);
                        }
                      }}
                      sx={{
                        '& .MuiAccordionSummary-content': {
                          width: '90%',
                        },
                      }}
                    >
                      <Grid
                        container
                        direction="row"
                        style={
                          activeAssets.find(
                            active =>
                              active.id?.gpsTrackerId !== undefined &&
                              active.id?.gpsTrackerId === gpsTracker.id,
                          )
                            ? {
                                display: 'grid',
                                gridTemplateColumns: '80% 20%',
                              }
                            : {}
                        }
                      >
                        <Tooltip title={gpsTracker.label || gpsTracker.id}>
                          <Typography
                            style={{
                              width: '100%',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            {gpsTracker.label && (
                              <span style={truncationStyle}>{gpsTracker.label}</span>
                            )}
                            {!gpsTracker.label && (
                              <span>
                                <span
                                  style={{
                                    color: '#924439',
                                  }}
                                >
                                  NO LABEL
                                </span>
                                <span style={truncationStyle}>{` (${gpsTracker.id})`}</span>
                              </span>
                            )}
                          </Typography>
                        </Tooltip>
                        {activeAssets.find(
                          active =>
                            active.id?.gpsTrackerId !== undefined &&
                            active.id?.gpsTrackerId === gpsTracker.id,
                        ) && (
                          <Typography
                            style={{
                              width: '100%',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            <span
                              style={{
                                marginLeft: '10px',
                                color: '#4CB8C4',
                                ...truncationStyle,
                              }}
                            >
                              ACTIVE
                            </span>
                          </Typography>
                        )}
                      </Grid>
                    </AccordionSummary>
                  </Accordion>
                );
              })}

            {/* Tools */}
            {!isLoading &&
              searchType?.id === 'TOOLS' &&
              availableTools.map(tool => {
                return (
                  <Accordion id={tool.uuid} key={tool.uuid} style={{ width: '98%' }}>
                    <AccordionSummary
                      onClick={() => {
                        setSelectedTool(tool);
                        const activeTool = findActiveAsset(tool.id);
                        if (!activeTool) return;
                        setSelectedAsset(
                          fromNullable({
                            id: activeTool.id,
                            label: tool.label,
                          }),
                        );
                      }}
                      sx={{
                        '& .MuiAccordionSummary-content': {
                          width: '90%',
                        },
                      }}
                    >
                      <Grid
                        container
                        direction="row"
                        style={
                          findActiveAsset(tool.id)
                            ? {
                                display: 'grid',
                                gridTemplateColumns: '80% 20%',
                              }
                            : {}
                        }
                      >
                        <Tooltip title={`${tool.id} ${tool.id ? ` [${tool.tool}] ` : ''}`}>
                          <Typography
                            style={{
                              width: '100%',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            {`${tool.id} ${tool.id ? ` [${tool.tool}] ` : ''}`}
                          </Typography>
                        </Tooltip>
                        {findActiveAsset(tool.id) && (
                          <Typography
                            style={{
                              width: '100%',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            <span
                              style={{
                                marginLeft: '10px',
                                color: '#4CB8C4',
                                ...truncationStyle,
                              }}
                            >
                              ACTIVE
                            </span>
                          </Typography>
                        )}
                      </Grid>
                    </AccordionSummary>
                  </Accordion>
                );
              })}
          </Grid>
        )}

        {/* Selected Geofence */}
        {selectedGeofence && (
          <Paper
            variant="outlined"
            style={{
              padding: '10px',
              height: 'fit-content',
            }}
          >
            <Box color="primary.main">{getFenceIcon(selectedGeofence.zone)}</Box>

            <Grid
              container
              direction="row"
              style={{
                width: '100%',
                marginBottom:
                  (!isMultipolygon && selectedGeofence.zone === FenceZone.none) || isLine
                    ? '0px'
                    : '10px',
                marginTop:
                  (!isMultipolygon && selectedGeofence.zone === FenceZone.none) || isLine
                    ? '0px'
                    : '-37px',
                justifyContent: 'flex-end',
              }}
            >
              <Tooltip title={'NAVIGATE TO'}>
                <IconButton
                  color="primary"
                  onClick={() => {
                    setNavigateTo(selectedGeofence.id);
                  }}
                >
                  <NearMe />
                </IconButton>
              </Tooltip>
            </Grid>

            <Grid
              container
              direction="row"
              spacing={1}
              marginLeft={0}
              justifyContent="left"
              alignItems="center"
            >
              <Tooltip title={selectedGeofence.name} style={lineLimitStyle}>
                <Typography variant="h5">{selectedGeofence.name}</Typography>
              </Tooltip>
            </Grid>

            <Grid
              container
              direction="column"
              style={{
                marginBottom: '10px',
                width: 'fit-content',
              }}
            >
              <Button
                style={{
                  marginLeft: '-7px',
                  color: '#fff',
                }}
                onClick={() => {
                  setDeselectFence(true);
                  setSelectedGeofence(undefined);
                  setSelectedMicrofence(undefined);
                  setSearchType({ id: 'GEOFENCES', value: 'Geofences' });
                  setGeofenceFilter({ perPage: 50 });
                  pageRef.current = 1;
                  setLayerFilter({
                    layer: {
                      name:
                        layers.find(lyr => lyr.id === selectedGeofence.layerId)?.name ?? 'Unknown',
                      id: selectedGeofence.layerId,
                    },
                  });
                  paginateGeofences({
                    ...geofenceFilter,
                    layerId: selectedGeofence.layerId,
                    perPage: 50,
                  } as GeofenceFilter);
                  setRefreshSearch(true);
                }}
              >
                {layers.find(lyr => lyr.id === selectedGeofence.layerId)?.name ?? 'Unknown'}
              </Button>
            </Grid>

            {selectedGeofence.geomobyProperties && (
              <>
                <Tooltip title={'Geofence Properties'}>
                  <Typography style={{ color: '#4CB8C4' }}>Geofence Properties</Typography>
                </Tooltip>
                <DataTable
                  rows={
                    Array.from(
                      Object.entries(selectedGeofence.geomobyProperties)
                        .sort()
                        .map(([name, value]) => {
                          return { name, value };
                        }),
                    ) as { name: string; value: string }[]
                  }
                ></DataTable>
              </>
            )}
          </Paper>
        )}

        {/* Selected Microfence */}
        {selectedMicrofence && (
          <Paper
            variant="outlined"
            style={{
              padding: '10px',
              height: 'fit-content',
            }}
          >
            <Box color="primary.main" style={{ marginTop: '10px' }}>
              {getMicroFenceIcon(selectedMicrofence.microfenceType)}
            </Box>

            <Grid
              container
              direction="row"
              style={{
                width: '100%',
                marginBottom: '10px',
                marginTop: '-37px',
                justifyContent: 'flex-end',
              }}
            >
              <Tooltip title={'NAVIGATE TO'}>
                <IconButton
                  color="primary"
                  onClick={() => {
                    setNavigateTo(selectedMicrofence.id);
                  }}
                >
                  <NearMe />
                </IconButton>
              </Tooltip>
            </Grid>

            <Grid
              container
              direction="row"
              spacing={1}
              marginLeft={0}
              justifyContent="left"
              alignItems="center"
            >
              <Tooltip title={selectedMicrofence.name} style={lineLimitStyle}>
                <Typography variant="h5">{selectedMicrofence.name}</Typography>
              </Tooltip>
            </Grid>

            <Grid
              container
              direction="column"
              style={{
                marginBottom: '10px',
                width: 'fit-content',
              }}
            >
              <Button
                style={{
                  marginLeft: '-7px',
                  color: '#fff',
                }}
                onClick={() => {
                  setDeselectFence(true);
                  setSearchType({ id: 'MICROFENCES', value: 'Microfences' });
                  setSelectedMicrofence(undefined);
                  setSelectedAsset(none);
                  setRefreshSearch(true);
                }}
              >
                {MICROFENCE_LAYER_LABEL}
              </Button>
            </Grid>

            <Grid container direction={'row'} justifyContent={'center'}>
              {selectedMicrofence.type === 'beacon' && (
                <>
                  <Grid
                    item
                    container
                    direction={'row'}
                    xs={12}
                    fontSize="90%"
                    sx={{
                      '& .MuiTypography-root': {
                        width: isMobile || window.innerWidth < 900 ? '78vw' : '22vw',
                        ...truncationStyle,
                      },
                    }}
                  >
                    <Tooltip title={'UUID'}>
                      <Typography style={{ color: '#4CB8C4' }}>UUID:</Typography>
                    </Tooltip>
                    <Tooltip
                      title={
                        (
                          selectedMicrofence.assetId as {
                            uuid: string;
                            major: string;
                            minor: string;
                          }
                        ).uuid
                      }
                    >
                      <Typography>
                        {
                          (
                            selectedMicrofence.assetId as {
                              uuid: string;
                              major: string;
                              minor: string;
                            }
                          ).uuid
                        }
                      </Typography>
                    </Tooltip>
                  </Grid>
                </>
              )}
              {(selectedMicrofence.type === 'gateway' ||
                selectedMicrofence.type === 'smartplug') && (
                <>
                  <Grid
                    item
                    container
                    direction={'row'}
                    xs={12}
                    fontSize="90%"
                    sx={{
                      '& .MuiTypography-root': {
                        width: isMobile || window.innerWidth < 900 ? '78vw' : '22vw',
                        ...truncationStyle,
                      },
                    }}
                  >
                    <Tooltip title={'Gateway ID'}>
                      <Typography style={{ color: '#4CB8C4', paddingRight: '5px' }}>
                        Gateway ID:
                      </Typography>
                    </Tooltip>
                    <Tooltip
                      title={
                        (selectedMicrofence.assetId as { gatewayId: string }).gatewayId ||
                        (selectedMicrofence.assetId as { smartplugId: string }).smartplugId
                      }
                    >
                      <Typography>
                        {(selectedMicrofence.assetId as { gatewayId: string }).gatewayId ||
                          (selectedMicrofence.assetId as { smartplugId: string }).smartplugId}
                      </Typography>
                    </Tooltip>
                  </Grid>
                </>
              )}
              {selectedMicrofence.type === 'device' && (
                <>
                  <Grid
                    item
                    container
                    direction={'row'}
                    xs={12}
                    fontSize="90%"
                    sx={{
                      '& .MuiTypography-root': {
                        width: isMobile || window.innerWidth < 900 ? '78vw' : '22vw',
                        ...truncationStyle,
                      },
                    }}
                  >
                    <Tooltip title={'Device ID'}>
                      <Typography style={{ color: '#4CB8C4', paddingRight: '5px' }}>
                        Device ID:
                      </Typography>
                    </Tooltip>
                    <Tooltip
                      title={
                        (selectedMicrofence.assetId as { deviceId: string }).deviceId || 'UNKNOWN'
                      }
                    >
                      <Typography>
                        {(selectedMicrofence.assetId as { deviceId: string }).deviceId || 'UNKNOWN'}
                      </Typography>
                    </Tooltip>
                  </Grid>
                </>
              )}

              {selectedMicrofence.type === 'beacon' && (
                <Grid item container direction={'row'} xs={12} fontSize="90%">
                  <Tooltip title={'Beacon ID'}>
                    <Typography style={{ color: '#4CB8C4', paddingRight: '5px' }}>
                      Beacon ID:
                    </Typography>
                  </Tooltip>
                  <Tooltip
                    title={
                      allBeaconsRef.current.find(
                        beacon =>
                          beacon.ibeacon?.uuid === String(selectedMicrofence?.assetId?.uuid) &&
                          beacon.ibeacon?.major === Number(selectedMicrofence?.assetId?.major) &&
                          beacon.ibeacon?.minor === Number(selectedMicrofence?.assetId?.minor),
                      )?.id
                    }
                  >
                    <Typography>
                      {
                        allBeaconsRef.current.find(
                          beacon =>
                            beacon.ibeacon?.uuid === String(selectedMicrofence?.assetId?.uuid) &&
                            beacon.ibeacon?.major === Number(selectedMicrofence?.assetId?.major) &&
                            beacon.ibeacon?.minor === Number(selectedMicrofence?.assetId?.minor),
                        )?.id
                      }
                    </Typography>
                  </Tooltip>
                </Grid>
              )}

              {selectedMicrofence.type === 'beacon' && (
                <Grid item container direction={'row'} xs={12} fontSize="90%">
                  <Tooltip title={'Major'}>
                    <Typography style={{ color: '#4CB8C4', paddingRight: '5px' }}>
                      Major:
                    </Typography>
                  </Tooltip>
                  <Tooltip
                    title={
                      (selectedMicrofence.assetId as { uuid: string; major: string; minor: string })
                        .major
                    }
                  >
                    <Typography>
                      {
                        (
                          selectedMicrofence.assetId as {
                            uuid: string;
                            major: string;
                            minor: string;
                          }
                        ).major
                      }
                    </Typography>
                  </Tooltip>
                </Grid>
              )}

              {selectedMicrofence.type === 'beacon' && (
                <Grid item container direction={'row'} xs={12} fontSize="90%">
                  <Tooltip title={'Minor'}>
                    <Typography style={{ color: '#4CB8C4', paddingRight: '5px' }}>
                      Minor:
                    </Typography>
                  </Tooltip>
                  <Tooltip
                    title={
                      (selectedMicrofence.assetId as { uuid: string; major: string; minor: string })
                        .minor
                    }
                  >
                    <Typography>
                      {
                        (
                          selectedMicrofence.assetId as {
                            uuid: string;
                            major: string;
                            minor: string;
                          }
                        ).minor
                      }
                    </Typography>
                  </Tooltip>
                </Grid>
              )}
            </Grid>

            <Grid item container direction={'row'} xs={12} fontSize="90%">
              <Tooltip title={'Zone'}>
                <Typography style={{ color: '#4CB8C4', paddingRight: '5px' }}>Zone:</Typography>
              </Tooltip>
              <Tooltip
                title={
                  MicrofenceZoneTypes.find(
                    z => z.id === (selectedMicrofence?.zone as MicrofenceZone),
                  )?.value ?? 'No Zone'
                }
              >
                <Typography>
                  {MicrofenceZoneTypes.find(
                    z => z.id === (selectedMicrofence?.zone as MicrofenceZone),
                  )?.value ?? 'No Zone'}
                </Typography>
              </Tooltip>
            </Grid>

            {selectedMicrofence.geomobyProperties && (
              <>
                <Tooltip title={'Microfence Properties'}>
                  <Typography style={{ color: '#4CB8C4' }}>Microfence Properties</Typography>
                </Tooltip>
                <DataTable
                  rows={
                    Array.from(
                      Object.entries(selectedMicrofence.geomobyProperties)
                        .sort()
                        .map(([name, value]) => {
                          return { name, value };
                        }),
                    ) as { name: string; value: string }[]
                  }
                ></DataTable>
              </>
            )}
          </Paper>
        )}

        {/* Selected Beacon */}
        {selectedBeacon && !selectedMicrofence && (
          <Paper
            variant="outlined"
            style={{
              padding: '10px',
              height: 'fit-content',
            }}
          >
            <Grid
              container
              direction="row"
              spacing={1}
              marginLeft={0}
              justifyContent="left"
              alignItems="center"
            >
              <Tooltip title={selectedBeacon.label || 'NO LABEL'} style={lineLimitStyle}>
                {selectedBeacon.label ? (
                  <Typography variant="h5">{selectedBeacon.label}</Typography>
                ) : (
                  <Typography style={{ color: '#924439' }} variant="h5">
                    {selectedBeacon.label}
                  </Typography>
                )}
              </Tooltip>
            </Grid>

            <Grid
              container
              direction="column"
              style={{
                marginBottom: '10px',
                width: 'fit-content',
              }}
            >
              <Button
                style={{
                  marginLeft: '-7px',
                  color: '#fff',
                }}
                onClick={() => {
                  setSearchType({ id: 'BEACONS', value: 'Beacons' });
                  setSelectedBeacon(undefined);
                  setSelectedAsset(none);
                  setRefreshSearch(true);
                }}
              >
                Beacons
              </Button>
            </Grid>

            {/*IDs */}
            <Grid container direction={'row'} justifyContent={'center'}>
              {
                <Grid
                  item
                  container
                  direction={'row'}
                  xs={12}
                  fontSize="90%"
                  sx={{
                    '& .MuiTypography-root': {
                      width: isMobile || window.innerWidth < 900 ? '78vw' : '22vw',
                      ...truncationStyle,
                    },
                  }}
                >
                  <Grid container direction={'row'}>
                    <Tooltip title={'Beacon ID'}>
                      <Typography
                        style={{
                          color: '#4CB8C4',
                          paddingRight: '5px',
                          width: 'fit-content',
                        }}
                      >
                        Beacon ID:
                      </Typography>
                    </Tooltip>

                    <Tooltip title={selectedBeacon.id}>
                      <Typography
                        style={{
                          width: 'fit-content',
                        }}
                      >
                        {selectedBeacon.id}
                      </Typography>
                    </Tooltip>
                  </Grid>
                </Grid>
              }

              {toNullable(selectedAsset)?.id?.beaconId !== selectedBeacon.id && (
                <Grid item container direction={'row'} xs={12} fontSize="90%">
                  <Tooltip title={'Status'}>
                    <Typography style={{ color: '#4CB8C4', paddingRight: '5px' }}>
                      Status:
                    </Typography>
                  </Tooltip>
                  <Tooltip title={'NOT CURRENTLY ACTIVE'}>
                    <Typography style={{ color: '#924439' }}>NOT CURRENTLY ACTIVE</Typography>
                  </Tooltip>
                </Grid>
              )}
            </Grid>
          </Paper>
        )}

        {/* Selected Device  */}
        {selectedDevice && !selectedMicrofence && (
          <Paper
            variant="outlined"
            style={{
              padding: '10px',
              height: 'fit-content',
            }}
          >
            <Grid
              container
              direction="row"
              spacing={1}
              marginLeft={0}
              justifyContent="left"
              alignItems="center"
            >
              <Tooltip title={selectedDevice.label || 'NO LABEL'} style={lineLimitStyle}>
                {selectedDevice.label ? (
                  <Typography variant="h5">{selectedDevice.label}</Typography>
                ) : (
                  <Typography style={{ color: '#924439' }} variant="h5">
                    {selectedDevice.label}
                  </Typography>
                )}
              </Tooltip>
            </Grid>

            <Grid
              container
              direction="column"
              style={{
                marginBottom: '10px',
                width: 'fit-content',
              }}
            >
              <Button
                style={{
                  marginLeft: '-7px',
                  color: '#fff',
                }}
                onClick={() => {
                  setSearchType({ id: 'DEVICES', value: 'Devices' });
                  setSelectedDevice(undefined);
                  setSelectedAsset(none);
                  setRefreshSearch(true);
                }}
              >
                Devices
              </Button>
            </Grid>

            <Grid container direction={'row'} justifyContent={'center'}>
              {
                <>
                  <Grid
                    item
                    container
                    direction={'row'}
                    xs={12}
                    fontSize="90%"
                    sx={{
                      '& .MuiTypography-root': {
                        width: isMobile || window.innerWidth < 900 ? '78vw' : '22vw',
                        ...truncationStyle,
                      },
                    }}
                  >
                    <Tooltip title={'Device ID'}>
                      <Typography style={{ color: '#4CB8C4', paddingRight: '5px' }}>
                        Device ID:
                      </Typography>
                    </Tooltip>
                    <Tooltip title={(selectedDevice.id as string) || 'UNKNOWN'}>
                      <Typography>{(selectedDevice.id as string) || 'UNKNOWN'}</Typography>
                    </Tooltip>
                  </Grid>
                  {toNullable(selectedAsset)?.id?.deviceId !== selectedDevice.id && (
                    <Grid item container direction={'row'} xs={12} fontSize="90%">
                      <Tooltip title={'Status'}>
                        <Typography style={{ color: '#4CB8C4', paddingRight: '5px' }}>
                          Status:
                        </Typography>
                      </Tooltip>
                      <Tooltip title={'NOT CURRENTLY ACTIVE'}>
                        <Typography style={{ color: '#924439' }}>NOT CURRENTLY ACTIVE</Typography>
                      </Tooltip>
                    </Grid>
                  )}
                </>
              }
            </Grid>
          </Paper>
        )}

        {/* Selected GPS Tracker  */}
        {selectedGPSTracker && (
          <Paper
            variant="outlined"
            style={{
              padding: '10px',
              height: 'fit-content',
            }}
          >
            <Grid
              container
              direction="row"
              spacing={1}
              marginLeft={0}
              justifyContent="left"
              alignItems="center"
            >
              <Tooltip title={selectedGPSTracker.label || 'NO LABEL'} style={lineLimitStyle}>
                {selectedGPSTracker.label ? (
                  <Typography variant="h5">{selectedGPSTracker.label}</Typography>
                ) : (
                  <Typography style={{ color: '#924439' }} variant="h5">
                    {selectedGPSTracker.label}
                  </Typography>
                )}
              </Tooltip>
            </Grid>

            <Grid
              container
              direction="column"
              style={{
                marginBottom: '10px',
                width: 'fit-content',
              }}
            >
              <Button
                style={{
                  marginLeft: '-7px',
                  color: '#fff',
                }}
                onClick={() => {
                  setSearchType({ id: 'GPSTRACKERS', value: 'GPS Trackers' });
                  setSelectedGPSTracker(undefined);
                  setSelectedAsset(none);
                  setRefreshSearch(true);
                }}
              >
                GPS Trackers
              </Button>
            </Grid>

            <Grid container direction={'row'} justifyContent={'center'}>
              {
                <>
                  <Grid
                    item
                    container
                    direction={'row'}
                    xs={12}
                    fontSize="90%"
                    sx={{
                      '& .MuiTypography-root': {
                        width: isMobile || window.innerWidth < 900 ? '78vw' : '22vw',
                        ...truncationStyle,
                      },
                    }}
                  >
                    <Tooltip title={'GPS Tracker ID'}>
                      <Typography style={{ color: '#4CB8C4', paddingRight: '5px' }}>
                        GPS Tracker ID:
                      </Typography>
                    </Tooltip>
                    <Tooltip title={(selectedGPSTracker.id as string) || 'UNKNOWN'}>
                      <Typography>{(selectedGPSTracker.id as string) || 'UNKNOWN'}</Typography>
                    </Tooltip>
                  </Grid>
                  {toNullable(selectedAsset)?.id?.gpsTrackerId !== selectedGPSTracker.id && (
                    <Grid item container direction={'row'} xs={12} fontSize="90%">
                      <Tooltip title={'Status'}>
                        <Typography style={{ color: '#4CB8C4', paddingRight: '5px' }}>
                          Status:
                        </Typography>
                      </Tooltip>
                      <Tooltip title={'NOT CURRENTLY ACTIVE'}>
                        <Typography style={{ color: '#924439' }}>NOT CURRENTLY ACTIVE</Typography>
                      </Tooltip>
                    </Grid>
                  )}
                </>
              }
            </Grid>
          </Paper>
        )}

        {/* Selected Tool  */}
        {selectedTool && (
          <Paper
            variant="outlined"
            style={{
              padding: '10px',
              height: 'fit-content',
            }}
          >
            <Grid
              container
              direction="row"
              spacing={1}
              marginLeft={0}
              justifyContent="left"
              alignItems="center"
            >
              <Tooltip title={selectedTool.label || 'NO LABEL'} style={lineLimitStyle}>
                {selectedTool.label ? (
                  <Typography variant="h5">{selectedTool.label}</Typography>
                ) : (
                  <Typography style={{ color: '#924439' }} variant="h5">
                    {selectedTool.label}
                  </Typography>
                )}
              </Tooltip>
            </Grid>

            <Grid
              container
              direction="column"
              style={{
                marginBottom: '10px',
                width: 'fit-content',
              }}
            >
              <Button
                style={{
                  marginLeft: '-7px',
                  color: '#fff',
                }}
                onClick={() => {
                  setSearchType({ id: 'TOOLS', value: 'Tools' });
                  setSelectedTool(undefined);
                  setSelectedAsset(none);
                  setRefreshSearch(true);
                }}
              >
                Tools
              </Button>
            </Grid>

            <Grid container direction={'row'} justifyContent={'center'}>
              {
                <>
                  <Grid item container direction={'row'} xs={12} fontSize="90%">
                    <Tooltip title={'Label'}>
                      <Typography style={{ color: '#4CB8C4', paddingRight: '5px' }}>
                        Label:
                      </Typography>
                    </Tooltip>
                    <Tooltip title={selectedTool.label || 'NO LABEL'}>
                      <Typography style={{ color: selectedTool.label ? '#fff' : '#924439' }}>
                        {selectedTool.label || 'NO LABEL'}
                      </Typography>
                    </Tooltip>
                  </Grid>

                  <Grid item container direction={'row'} xs={12} fontSize="90%">
                    <Tooltip title={'Tool Type'}>
                      <Typography style={{ color: '#4CB8C4', paddingRight: '5px' }}>
                        Tool Type:
                      </Typography>
                    </Tooltip>
                    <Tooltip title={selectedTool.tool}>
                      <Typography>{selectedTool.tool}</Typography>
                    </Tooltip>
                  </Grid>

                  {!findActiveAsset(selectedTool.id) && (
                    <Grid item container direction={'row'} xs={12} fontSize="90%">
                      <Tooltip title={'Status'}>
                        <Typography style={{ color: '#4CB8C4', paddingRight: '5px' }}>
                          Status:
                        </Typography>
                      </Tooltip>
                      <Tooltip title={'NOT CURRENTLY ACTIVE'}>
                        <Typography style={{ color: '#924439' }}>NOT CURRENTLY ACTIVE</Typography>
                      </Tooltip>
                    </Grid>
                  )}
                </>
              }
            </Grid>
          </Paper>
        )}
      </Grid>

      {/* Location */}
      {searchType?.id === 'LOCATIONS' && !selectedElement() && layers && (
        <>
          <div
            style={{
              width: '100px',
              alignSelf: 'start',
              margin: '0px 0px -20px 0px',
            }}
          >
            <Tooltip title={'Search'}>
              <Typography variant="h5">Search</Typography>
            </Tooltip>
          </div>
          <LocationSearch
            isGeofenceEditor={false}
            setLocationSearchData={setLocationSearchData}
            currentCenter={currentCenter}
            setLocationDisplay={setLocationDisplay}
          ></LocationSearch>

          {locationDisplay && (
            <Paper
              variant="outlined"
              style={{
                padding: '10px',
                height: 'fit-content',
              }}
            >
              <Grid
                container
                direction="row"
                sx={{
                  '& .MuiTypography-root': {
                    marginLeft: '0px',
                    width: isMobile || window.innerWidth < 900 ? '78vw' : '22vw',
                    ...truncationStyle,
                  },
                  '& .MuiStack-root': {
                    width: isMobile || window.innerWidth < 900 ? '78vw' : '22vw',
                    justifyContent: 'start',
                  },
                }}
              >
                <Stack direction="row" spacing={1} justifyContent="center" alignItems="center">
                  <Box color="primary.main">
                    <LocationOn />
                  </Box>
                  <Tooltip title={locationDisplay.label}>
                    <Typography variant="h5">{locationDisplay.label}</Typography>
                  </Tooltip>
                </Stack>
              </Grid>
              <Grid
                style={{
                  marginLeft: '32px',
                }}
              >
                <Tooltip title={locationDisplay.coordinates}>
                  <Typography
                    style={{
                      marginLeft: '-7px',
                      color: '#fff',
                    }}
                  >
                    {`${locationDisplay.coordinates[0]}, ${locationDisplay.coordinates[1]}`}
                  </Typography>
                </Tooltip>
              </Grid>
            </Paper>
          )}
        </>
      )}
    </>
  );
};
