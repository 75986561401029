/* eslint-disable  @typescript-eslint/no-empty-interface */
import { GridRowData } from '@material-ui/data-grid';
import { FormatListBulleted, PhoneIphone } from '@mui/icons-material';
import MapIcon from '@mui/icons-material/Map';
import { Box, Grid, Paper, Stack, Tooltip, Typography } from '@mui/material';
import { map, option } from 'fp-ts/es6';
import { fromNullable, isNone, isSome, toNullable, toUndefined } from 'fp-ts/es6/Option';
import { ordString } from 'fp-ts/es6/Ord';
import { pipe } from 'fp-ts/es6/pipeable';
import { Extent } from 'ol/extent';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { CenteredProgress, Header, Item, PaperBox, Sidebar, SubHeader } from '../../Common/Sidebar';
import { LiveMapUpdate } from '../../hooks/geomoby/LiveMapActions';
import {
  LiveMapRenderer,
  localiseAssetId,
  isLocalisedAssetId,
  MapType,
  unlocaliseAssetId,
} from '../../hooks/geomoby/LiveMapRenderer';
import { useGeomobyLiveStream } from '../../hooks/geomoby/useGeomobyLiveStream';
import { useLiveMapLoader } from '../../hooks/geomoby/useLiveMapLoader';
import { RenderAssetState } from './AssetRenderer';
import { AssetState, DeviceLocation, SensedTriggeredEvent } from './Messages';
import { SidebarAndMap } from './SidebarAndMap/SidebarAndMap';
import { WelfareCheck } from './WelfareCheck/WelfareCheck';
import { debounce, uniqBy } from 'lodash';
import { GeofenceSearchToolbar } from './GeofenceSearchToolbar';
import { AssetLocation, LocationDisplayType, LocationSearchData } from './Toolbar/LocationSearch';
import { DeviceOrTagLocation } from './SidebarAndMap/Tiles/DeviceOrTagLocation';
import { MICROFENCE, MICROFENCE_LAYER_ID } from './BeaconUtils';
import { LiveMapSearch } from './LiveMapSearch';
import { LiveMapFilter } from './LiveMapFilter';
import {
  PortableAssetFilter,
  GeofenceFilter,
  LayerFilter,
  MicrofenceFilter,
  PortableAsset,
  PortableAssetTool,
  SearchType,
  ToolFilter,
  ToolType,
} from './types';
import { Feature } from 'ol';
import { Point } from 'ol/geom';
import { transform } from 'ol/proj';
import { TRACKED_DEVICE } from './MapDefaults';
import { ToolSensedBy } from './SidebarAndMap/Tiles/ToolSensedBy';

const fontSizeIcon = 30;

export const mapIcon = <MapIcon style={{ fontSize: fontSizeIcon }} color="secondary" />;

interface ILiveMap {}

export interface LiveMapState {
  assets: Map<string, AssetState>;
  selectedAsset: option.Option<string>;
}

export const getTda = ({
  selected,
  assets,
  selectedGeofence,
}: {
  selected: option.Option<SelectedAsset>;
  assets: Map<string, AssetState>;
  selectedGeofence: GridRowData | undefined;
}): option.Option<AssetState[]> => {
  const selectedAsset = toNullable(selected);
  if (selectedGeofence) {
    const foundAssets = Array.from(assets.values()).filter(
      asset =>
        isSome(asset.lastFenceEvent) && asset.lastFenceEvent.value.fenceId === selectedGeofence.id,
    );
    return fromNullable(foundAssets);
  }

  if (selectedAsset === null || selectedAsset.label === undefined) return option.none;

  if (!isLocalisedAssetId(selectedAsset.label)) {
    const assetOrMicrofence = Array.from(assets.values()).filter(
      ({ id }) => JSON.stringify(id) === JSON.stringify(selectedAsset.id),
    );
    return option.fromNullable([assetOrMicrofence[0]]);
  }
  const relatedAsset = assets.get(unlocaliseAssetId(selectedAsset.label));
  if (!relatedAsset) return option.none;

  const sensed = toNullable(relatedAsset.lastSensed)?.assets || [];
  const latestLocation = Array.from(assets.values())
    .filter(asset => sensed?.find(s => s.label === asset.label))
    .sort(
      (a, b) =>
        new Date(toNullable(b.lastLocation)?.timestamp || 0).getTime() -
        new Date(toNullable(a.lastLocation)?.timestamp || 0).getTime(),
    )
    .at(0);

  const localisedAsset: AssetState | undefined = {
    id: { ...selectedAsset.id, localised: 'true' },
    label: selectedAsset.label,
    lastSensed: relatedAsset.lastSensed,
    lastLocalBeacons: relatedAsset.lastLocalBeacons,
    lastLocation: latestLocation?.lastLocation ?? option.none,
    lastFenceEvent: option.none,
    lastTemp: option.none,
    lastTempRangeEvent: option.none,
    lastWelfareCheckResponse: option.none,
    recentSensedTriggered: option.none,
  };
  return option.fromNullable([localisedAsset]);
};

export const getSearchSelectOptions = (assets: Map<string, AssetState>): SelectedAsset[] => {
  const as = uniqBy(
    Array.from(assets.values())
      .flatMap(asset => {
        return {
          label: asset.label,
          id: asset.id,
        };
      })
      .filter(asset => !asset.id.mqttgatewayId && !asset.id.gatewayId),
    a => a.label,
  );
  return as.sort((a, b) => a.label.localeCompare(b.label));
};

export const getLastLocationOfAsset = (tdas: AssetState[]): DeviceLocation | undefined => {
  const lastLocation = tdas.find(tda => toNullable(tda.lastLocation))?.lastLocation;
  if (!lastLocation || isNone(lastLocation)) return;
  return lastLocation.value;
};

export type SelectedAsset = {
  id: Record<string, string | undefined> | undefined;
  label: string | undefined;
  prefix?: string;
  following?: boolean | undefined;
};

export const LiveMap: FC<ILiveMap> = () => {
  const mapType: option.Option<MapType> = option.some('OUTDOOR_LIVE_MAP');

  const liveStreamData = useGeomobyLiveStream({ useFor: 'livemap' });

  const liveStreamState = liveStreamData.state;
  const [streamedMicrofences, setStreamedMicrofences] = useState<SelectedAsset[]>([]);
  const [lastKnownAssetLocation, setLastKnownAssetLocation] = useState<AssetLocation | undefined>();
  const [layerId, setLayerId] = useState<string | undefined>();
  const [locationDisplay, setLocationDisplay] = useState<LocationDisplayType>();
  const [searchString, setSearchString] = useState<string | undefined>();
  const streamedAssets: SelectedAsset[] = useMemo(
    () => [
      ...streamedMicrofences.map(o => ({ ...o, prefix: 'Microfence' })),
      ...getSearchSelectOptions(liveStreamState.assets)
        .map(o => ({ ...o, prefix: TRACKED_DEVICE }))
        .filter(o => !streamedMicrofences.find(m => JSON.stringify(m.id) === JSON.stringify(o.id))),
    ],
    [liveStreamState.assets, streamedMicrofences],
  );

  const [selectedAsset, setSelectedAsset] = useState<option.Option<SelectedAsset>>(option.none);
  const [selectedGeofence, setSelectedGeofence] = useState<GridRowData | undefined>();
  const tdas = getTda({
    selected: selectedAsset,
    assets: liveStreamState.assets,
    selectedGeofence,
  });

  useEffect(() => {
    setSelectedGeofence(undefined);
  }, [searchString]);

  const lml = useLiveMapLoader();

  useEffect(() => {
    if (isSome(lml.result)) {
      const foundStreamedMicrofences: SelectedAsset[] = lml.result.value.microfences.map(
        ({ data }) => ({
          id: data.assetId,
          label: data.fenceName ?? data.fenceId,
        }),
      );
      setStreamedMicrofences(
        foundStreamedMicrofences.sort((a, b) => String(a.label).localeCompare(String(b.label))),
      );
    }
  }, [lml.result]);

  const [clearFilter, setClearFilter] = useState<boolean>(false);
  const [portableAssetFilter, setPortableAssetFilter] = useState<PortableAssetFilter>({
    perPage: 50,
  });
  const [currentCenter, setCurrentCenter] = useState<number[] | undefined>();
  const [deselectFence, setDeselectFence] = useState<boolean>(false);
  const [geofenceFilter, setGeofenceFilter] = useState<GeofenceFilter>({ perPage: 50 });
  const [geomobyProperties, setGeomobyProperties] = useState<Record<string, string> | undefined>();
  const [microfenceFilter, setMicrofenceFilter] = useState<MicrofenceFilter>();
  const [layerFilter, setLayerFilter] = useState<LayerFilter | undefined>();
  const [locationSearchData, setLocationSearchData] = useState<LocationSearchData | undefined>();
  const [navigateTo, setNavigateTo] = useState<string | null>(null);
  const [refreshSearch, setRefreshSearch] = useState<boolean>(false);
  const [resetStyling, setResetStyling] = useState<boolean>(false);
  const [searchType, setSearchType] = useState<SearchType | undefined>();
  const [selectedBeacon, setSelectedBeacon] = useState<PortableAsset | undefined>();
  const [selectedDevice, setSelectedDevice] = useState<PortableAsset | undefined>();
  const [selectedGPSTracker, setSelectedGPSTracker] = useState<PortableAsset | undefined>();
  const [selectedMicrofence, setSelectedMicrofence] = useState<GridRowData | undefined>();
  const [selectedTool, setSelectedTool] = useState<PortableAssetTool | undefined>();
  const [selectedFromMap, setSelectedFromMap] = useState<boolean>(false);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [toolFilter, setToolFilter] = useState<ToolFilter>({ perPage: 50 });
  const [toolTypes, setToolTypes] = useState<ToolType[]>([]);
  const [userExtent, setUserExtent] = useState<option.Option<Extent>>(option.none);

  useEffect(() => {
    if (!lastKnownAssetLocation) {
      setLocationDisplay(undefined);
      setLocationSearchData(undefined);
      return;
    }
    setLocationSearchData({
      coords: transform(
        [lastKnownAssetLocation.lon, lastKnownAssetLocation.lat],
        'EPSG:4326',
        'EPSG:3857',
      ),
      isLastKnownLocation: true,
    });
  }, [lastKnownAssetLocation]);

  const assetIsSelected = (): boolean => {
    return (
      isSome(selectedAsset) ||
      !!selectedBeacon ||
      !!selectedDevice ||
      !!selectedGeofence ||
      !!selectedGPSTracker ||
      !!selectedMicrofence ||
      !!selectedTool
    );
  };

  const uiWfcAllow = () => {
    if (resetStyling) {
      setResetStyling(false);
    }
  };

  const uiWfcClear = () => {
    if (!resetStyling) {
      setResetStyling(true);
    }
  };

  return (
    <SidebarAndMap
      sidebar={
        <Sidebar>
          <WelfareCheck allow={uiWfcAllow} reset={uiWfcClear} />
          <Item>
            {isSome(lml.result) && (
              <LiveMapSearch
                layers={Array.from(lml.result.value.layers.entries())
                  .map(([key, lyr]) => {
                    return { id: key, name: lyr.name };
                  })
                  ?.sort((a, b) => a.name.localeCompare(b.name))}
                microfences={lml.result.value.microfences.sort((a, b) =>
                  a.feature.get('name').localeCompare(b.feature.get('name')),
                )}
                selectedBeacon={selectedBeacon}
                setSelectedBeacon={setSelectedBeacon}
                selectedDevice={selectedDevice}
                setSelectedDevice={setSelectedDevice}
                selectedGeofence={selectedGeofence}
                setSelectedGeofence={setSelectedGeofence}
                selectedMicrofence={selectedMicrofence}
                setSelectedMicrofence={setSelectedMicrofence}
                selectedTool={selectedTool}
                setSelectedTool={setSelectedTool}
                selectedGPSTracker={selectedGPSTracker}
                setSelectedGPSTracker={setSelectedGPSTracker}
                setToolTypes={setToolTypes}
                setExtent={setUserExtent}
                searchType={searchType}
                setSearchType={setSearchType}
                layerFilter={layerFilter}
                setLayerFilter={setLayerFilter}
                portableAssetFilter={portableAssetFilter}
                setPortableAssetFilter={setPortableAssetFilter}
                toolFilter={toolFilter}
                setToolFilter={setToolFilter}
                geofenceFilter={geofenceFilter}
                setGeofenceFilter={setGeofenceFilter}
                microfenceFilter={microfenceFilter}
                setMicrofenceFilter={setMicrofenceFilter}
                clearFilter={clearFilter}
                setClearFilter={setClearFilter}
                showFilter={showFilter}
                setShowFilter={setShowFilter}
                refreshSearch={refreshSearch}
                setRefreshSearch={setRefreshSearch}
                activeAssets={streamedAssets}
                selectedAsset={selectedAsset}
                setSelectedAsset={setSelectedAsset}
                setLocationSearchData={setLocationSearchData}
                currentCenter={currentCenter}
                setLastKnownAssetLocation={setLastKnownAssetLocation}
                replayStartDateTime={null}
                locationDisplay={locationDisplay}
                setLocationDisplay={setLocationDisplay}
                setNavigateTo={setNavigateTo}
                setSelectedFromMap={setSelectedFromMap}
                setDeselectFence={setDeselectFence}
              ></LiveMapSearch>
            )}
            {isSome(lml.result) && (
              <LiveMapFilter
                searchType={searchType}
                layerFilter={layerFilter}
                setLayerFilter={setLayerFilter}
                portableAssetFilter={portableAssetFilter}
                setPortableAssetFilter={setPortableAssetFilter}
                toolFilter={toolFilter}
                setToolFilter={setToolFilter}
                geofenceFilter={geofenceFilter}
                setGeofenceFilter={setGeofenceFilter}
                microfenceFilter={microfenceFilter}
                setMicrofenceFilter={setMicrofenceFilter}
                selectedMicrofence={selectedMicrofence}
                clearFilter={clearFilter}
                setClearFilter={setClearFilter}
                showFilter={showFilter}
                setShowFilter={setShowFilter}
                setRefreshSearch={setRefreshSearch}
                toolTypes={toolTypes}
                setToolTypes={setToolTypes}
              ></LiveMapFilter>
            )}
            {(selectedDevice || selectedGPSTracker) && isNone(selectedAsset) && (
              <DeviceOrTagLocation
                heading={'Last Known Location'}
                deviceLocation={lastKnownAssetLocation}
              ></DeviceOrTagLocation>
            )}
            {assetIsSelected() && (
              <>
                {(toNullable(tdas) ?? []).map((tda: AssetState) => {
                  if (!tda) return;
                  return RenderAssetState(
                    fromNullable(tda),
                    toUndefined(selectedAsset) === undefined,
                  );
                })}
              </>
            )}
            <>
              {selectedTool && isSome(tdas) && (
                <ToolSensedBy
                  lastLocation={getLastLocationOfAsset(tdas.value)}
                  microfences={isSome(lml.result) ? lml.result.value.microfences : []}
                  activeAssets={streamedAssets}
                  setSelectedDevice={setSelectedDevice}
                  setSelectedMicrofence={setSelectedMicrofence}
                  setSelectedTool={setSelectedTool}
                  setSelectedAsset={setSelectedAsset}
                ></ToolSensedBy>
              )}
            </>
          </Item>
        </Sidebar>
      }
      map={
        <LiveMapRenderer
          selected={selectedAsset}
          setSelected={setSelectedAsset}
          lsd={liveStreamData}
          selectedGeofence={selectedGeofence}
          setSelectedGeofence={setSelectedGeofence}
          selectedMicrofence={selectedMicrofence}
          setSelectedMicrofence={setSelectedMicrofence}
          setSelectedBeacon={setSelectedBeacon}
          setSelectedDevice={setSelectedDevice}
          setSelectedTool={setSelectedTool}
          setSelectedGPSTracker={setSelectedGPSTracker}
          userExtent={userExtent}
          setUserExtent={setUserExtent}
          onExtentChanged={lml.setBounds}
          lml={lml.result}
          fencesLoading={lml.loading}
          resetStyling={resetStyling}
          mapType={mapType}
          setGeomobyProperties={setGeomobyProperties}
          locationSearchData={locationSearchData}
          setCurrentCenter={setCurrentCenter}
          locationDisplay={locationDisplay}
          navigateTo={navigateTo}
          setNavigateTo={setNavigateTo}
          selectedFromMap={selectedFromMap}
          setSelectedFromMap={setSelectedFromMap}
          deselectFence={deselectFence}
          setDeselectFence={setDeselectFence}
        />
      }
    />
  );
};
