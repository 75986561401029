import { useAtomValue } from 'jotai';
import { KNOWN_TOOLS_BY_ID } from '../store/tools';
import { useCallback, useEffect } from 'react';
import { PortableAssetTool } from '../Components/Map/types';

export const toToolLabel = (
  knownToolsRecord: Record<string, PortableAssetTool | undefined>,
  ids: Record<string, string | undefined>,
  label: string | undefined,
  fallback?: string,
) => {
  const beaconId = ids.beaconId;
  if (!beaconId) return label ?? fallback;

  const toolType = knownToolsRecord[beaconId]?.tool;

  if (!toolType) return label ?? fallback;

  if (label) return `${label} (${beaconId}; ${toolType})`;

  return `${beaconId} (${toolType})`;
};

export const useAugmentedToolLabel = () => {
  const knownToolsRecord = useAtomValue(KNOWN_TOOLS_BY_ID);
  return useCallback(
    (ids: Record<string, string | undefined>, label: string | undefined, fallback?: string) =>
      toToolLabel(knownToolsRecord, ids, label, fallback),
    [knownToolsRecord],
  );
};
