import {
  CheckCircle,
  Login,
  Logout,
  Thermostat,
  Radar,
  Sensors,
  SensorsOff,
  WifiTetheringError,
  HistoryToggleOff,
} from '@mui/icons-material';
import PersonPinCircle from '@mui/icons-material/PersonPinCircle';
import TrackChanges from '@mui/icons-material/TrackChanges';
import { Stack, Tooltip, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { formatDistance } from 'date-fns';
import { option } from 'fp-ts/es6';
import { fromNullable, isNone, isSome, toNullable } from 'fp-ts/es6/Option';
import React from 'react';
import { PaperBox, SubHeader } from '../../Common/Sidebar';
import TemperatureRangeIcon from '../../Style/icons/TemperatureRangeIcon';
import {
  AssetState,
  DeviceLocation,
  GeofenceEvent,
  LocalBeacons,
  ReportBeacon,
  SensedAsset,
  SensedAssetsReport,
  SensedTemp,
  SensedTriggeredEvent,
  TempRangeEvent,
  WelfareCheckResponse,
} from './Messages';
import { rest } from 'lodash';
import { AssetLocation } from './Toolbar/LocationSearch';
import { DeviceOrTagLocation } from './SidebarAndMap/Tiles/DeviceOrTagLocation';
import { useAtomValue } from 'jotai';
import { KNOWN_TOOLS } from '../../store/tools';

export const lineLimitStyle: React.CSSProperties | undefined = {
  overflow: 'hidden',
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  overflowWrap: 'break-word',
};

export const truncationStyle: React.CSSProperties | undefined = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

const RenderWelfareCheckResponse = ({ wc }: { wc: option.Option<WelfareCheckResponse> }) => (
  <>
    {option.isSome(wc) ? (
      <PaperBox>
        <SubHeader icon={<CheckCircle />} timestamp={wc.value.timestamp}>
          Welfare Check
        </SubHeader>
        <div style={{ marginLeft: '1rem' }}>
          <Tooltip title={wc.value.ok ? 'OK!' : 'Not OK'}>
            <pre style={truncationStyle}>Response: {wc.value.ok ? 'OK!' : 'Not OK'}</pre>
          </Tooltip>
          <Tooltip title={new Date(wc.value.timestamp).toLocaleString()}>
            <pre style={truncationStyle}>at: {new Date(wc.value.timestamp).toLocaleString()}</pre>
          </Tooltip>
        </div>
      </PaperBox>
    ) : (
      <></>
    )}
  </>
);

const RenderSensedTemp = ({ dl }: { dl: option.Option<SensedTemp> }) => (
  <>
    {option.isSome(dl) ? (
      <PaperBox>
        <SubHeader icon={<Thermostat />} timestamp={dl.value.timestamp}>
          Temperature
        </SubHeader>
        <div style={{ marginLeft: '1rem' }}>
          <Tooltip title={dl.value.temp.toString()}>
            <pre style={truncationStyle}>temp: {dl.value.temp}</pre>
          </Tooltip>
          <Tooltip title={new Date(dl.value.timestamp).toLocaleString()}>
            <pre style={truncationStyle}>at: {new Date(dl.value.timestamp).toLocaleString()}</pre>
          </Tooltip>
        </div>
      </PaperBox>
    ) : (
      <></>
    )}
  </>
);

const RenderTempRangeEvent = ({ dl }: { dl: option.Option<TempRangeEvent> }) => {
  const entered = isSome(dl) && ('' + dl.value.event).toLowerCase() === 'entered';
  return (
    <>
      {option.isSome(dl) ? (
        <PaperBox>
          <SubHeader
            icon={<TemperatureRangeIcon style={{}} color="inhert" />}
            timestamp={dl.value.timestamp}
          >
            {entered ? 'Entered' : 'Exited'} Temperature Event
          </SubHeader>
          <div style={{ marginLeft: '1rem' }}>
            <Tooltip title={dl.value.tempRangeLabel}>
              <pre style={truncationStyle}>name: {dl.value.tempRangeLabel}</pre>
            </Tooltip>
            <Tooltip title={new Date(dl.value.timestamp).toLocaleString()}>
              <pre style={truncationStyle}>at: {new Date(dl.value.timestamp).toLocaleString()}</pre>
            </Tooltip>
          </div>
        </PaperBox>
      ) : (
        <></>
      )}
    </>
  );
};

const RenderDeviceLocation = ({ dl }: { dl: option.Option<DeviceLocation | AssetLocation> }) => (
  <>
    {option.isSome(dl) && (
      <DeviceOrTagLocation heading={'Location'} deviceLocation={dl.value}></DeviceOrTagLocation>
    )}
  </>
);

const RenderGeofenceEvent = ({
  ge,
  multiEvent,
}: {
  ge: option.Option<GeofenceEvent>;
  multiEvent: boolean;
}) => {
  const entered = option.isSome(ge) && ('' + ge.value.event).toLowerCase() === 'entered';
  const dwell = option.isSome(ge) && ('' + ge.value.event).toLowerCase() === 'dwell';
  return (
    <>
      {option.isSome(ge) ? (
        <PaperBox>
          <SubHeader
            icon={dwell ? <HistoryToggleOff /> : entered ? <Login /> : <Logout />}
            timestamp={ge.value.timestamp}
          >
            {dwell
              ? 'Dwell '
              : (ge.value.type === 'polygon' || ge.value.type === 'multipolygon') &&
                (entered ? 'Entered ' : 'Exited ')}
            {!dwell && ge.value.type === 'line' && (entered ? 'Left to Right ' : 'Right to Left ')}
            Geofence
          </SubHeader>
          <div style={{ marginLeft: '1rem', width: '85%' }}>
            {multiEvent && (
              <Tooltip title={ge.value.assetId}>
                <pre style={truncationStyle}>Asset ID: {ge.value.assetId},</pre>
              </Tooltip>
            )}
            {!multiEvent && (
              <Tooltip title={ge.value.fenceName}>
                <pre style={truncationStyle}>fence name: {ge.value.fenceName},</pre>
              </Tooltip>
            )}
            {dwell && (
              <Tooltip title={ge.value.dwellSeconds}>
                <pre style={truncationStyle}>dwell time: {ge.value.dwellSeconds} seconds,</pre>
              </Tooltip>
            )}
            <Tooltip title={ge.value.y.toFixed(5).toString()}>
              <pre style={truncationStyle}>lat: {ge.value.y.toFixed(5)},</pre>
            </Tooltip>
            <Tooltip title={ge.value.x.toFixed(5).toString()}>
              <pre style={truncationStyle}>lon: {ge.value.x.toFixed(5)}</pre>
            </Tooltip>
            <Tooltip title={new Date(ge.value.timestamp).toLocaleString()}>
              <pre style={truncationStyle}>at: {new Date(ge.value.timestamp).toLocaleString()}</pre>
            </Tooltip>
          </div>
        </PaperBox>
      ) : (
        <></>
      )}
    </>
  );
};

const RenderSensedTriggered = ({ st }: { st: SensedTriggeredEvent }) => (
  <Stack direction="row">
    <Box color="primary.main" pr={1} display="flex" flexWrap="wrap" alignContent="center">
      {st.entered ? <Sensors /> : <SensorsOff />}
    </Box>
    <div style={{ width: '85%' }}>
      <Tooltip title={`${st.sensedLabel} ${st.entered ? 'entered' : 'exited'}`}>
        <pre style={truncationStyle}>
          {st.sensedLabel} {st.entered ? 'entered' : 'exited'}
        </pre>
      </Tooltip>
      <Tooltip title={new Date(st.timestamp).toLocaleString()}>
        <pre style={truncationStyle}>at: {new Date(st.timestamp).toLocaleString()}</pre>
      </Tooltip>
    </div>
  </Stack>
);

const RenderMicrofenceEvents = ({ sts }: { sts: option.Option<SensedTriggeredEvent[]> }) => {
  return (
    <>
      {option.isSome(sts) ? (
        <PaperBox>
          <SubHeader icon={<WifiTetheringError />} timestamp={sts.value[0].timestamp}>
            Entered/Exited
          </SubHeader>
          <Stack direction="column" divider={<Divider color="gray" />} spacing={1}>
            {sts.value.map((st, i) => (
              <RenderSensedTriggered st={st} key={i} />
            ))}
          </Stack>
        </PaperBox>
      ) : (
        <></>
      )}
    </>
  );
};

const seconds = (timestamp: string): string => {
  return formatDistance(new Date(timestamp), new Date(), { addSuffix: true });
};

const SecondsAgo = (props: { since: string }) => {
  return <Typography>{seconds(props.since)}</Typography>;
};

const RenderSensedAsset = ({ a }: { a: SensedAsset }) => {
  const knownTools = useAtomValue(KNOWN_TOOLS);
  return (
    <div>
      <Tooltip title={a.label}>
        <pre style={truncationStyle}>
          {a.beaconId ? (knownTools.find(t => t.id === a.beaconId) ? 'tool' : 'beacon') : 'asset'}:{' '}
          {a.label}
        </pre>
      </Tooltip>

      {a.beaconId && (
        <Tooltip title={a.beaconId}>
          <pre style={truncationStyle}>ID: {a.beaconId}</pre>
        </Tooltip>
      )}
      <Tooltip title={a.rssi.toString()}>
        <pre style={truncationStyle}>rssi: {a.rssi}</pre>
      </Tooltip>
      {a.distance !== undefined && (
        <Tooltip title={a.distance.toString()}>
          <pre style={truncationStyle}>distance: {a.distance}</pre>
        </Tooltip>
      )}
      {Number.isFinite(a.battery_level) && a.battery_level !== undefined && a.battery_level >= 0 && (
        <Tooltip title={a.battery_level ? a.battery_level.toString() : ''}>
          <pre style={truncationStyle}>battery_level: {a.battery_level}</pre>
        </Tooltip>
      )}
    </div>
  );
};
const keyForBeacon = (sb: ReportBeacon): string => {
  return '' + sb.uuid + '_' + sb.major + ' ' + sb.minor;
};

const RenderBeacons = ({ assets }: { assets: SensedAsset[] }) => (
  <div style={{ marginLeft: '1rem' }}>
    <Stack direction="column" divider={<Divider color="gray" />} spacing={1}>
      {assets.map((a, i) => (
        <RenderSensedAsset a={a} key={i} />
      ))}
    </Stack>
  </div>
);

const RenderNearbySensedAssets = ({
  assets,
  timestamp,
  title,
}: {
  assets: SensedAsset[];
  timestamp: string;
  title: string;
}) => (
  <PaperBox key={title}>
    <SubHeader icon={<TrackChanges />} timestamp={timestamp}>
      {title}
    </SubHeader>
    <RenderBeacons assets={assets} />
  </PaperBox>
);
const RenderSensedBeacons = ({ sensed }: { sensed: option.Option<SensedAssetsReport> }) => (
  <>
    {option.isSome(sensed) && sensed.value.assets.length > 0 && (
      <RenderNearbySensedAssets
        assets={sensed.value.assets}
        timestamp={sensed.value.timestamp}
        title="BLE Tags"
      />
    )}
  </>
);

const RenderLocalBeacons = ({ beacons }: { beacons: option.Option<LocalBeacons> }) => (
  <>
    {option.isSome(beacons) && beacons.value.nearbyBeacons.length > 0 && (
      <>
        <RenderDeviceLocation dl={option.some(beacons.value.deviceLocation)} />
        <RenderNearbySensedAssets
          assets={beacons.value.nearbyBeacons.map(
            ({ label, uuid, major, minor, beacon_id, ...rest }) => ({
              ...rest,
              uuid,
              major,
              minor,
              label,
              beaconId: beacon_id,
              id: { uuid, major, minor },
            }),
          )}
          timestamp={beacons.value.deviceLocation.timestamp}
          title="Nearby Beacons"
        />
      </>
    )}
  </>
);

// Needed for IndoorMapDumper
// Needed for LiveStreamDumper and OutdoorStreamDumper (Live Map Tab)
export const RenderAssetState = (aso: option.Option<AssetState>, multiEvent: boolean) => {
  if (isNone(aso)) return <></>;
  if (multiEvent) {
    return (
      <Stack key={aso.value.label} direction="column" spacing={1}>
        <RenderGeofenceEvent
          ge={option.chain((a: AssetState) => a.lastFenceEvent)(aso)}
          multiEvent={multiEvent}
        />
      </Stack>
    );
  }

  return (
    <Stack key={aso.value.label} direction="column" spacing={1}>
      <RenderWelfareCheckResponse
        wc={option.chain((a: AssetState) => a.lastWelfareCheckResponse)(aso)}
      />
      <RenderSensedTemp dl={option.chain((a: AssetState) => a.lastTemp)(aso)} />
      <RenderTempRangeEvent dl={option.chain((a: AssetState) => a.lastTempRangeEvent)(aso)} />
      <RenderDeviceLocation dl={option.chain((a: AssetState) => a.lastLocation)(aso)} />
      <RenderGeofenceEvent
        ge={option.chain((a: AssetState) => a.lastFenceEvent)(aso)}
        multiEvent={false}
      />
      <RenderMicrofenceEvents sts={option.chain((a: AssetState) => a.recentSensedTriggered)(aso)} />
      <RenderSensedBeacons sensed={option.chain((a: AssetState) => a.lastSensed)(aso)} />
      <RenderLocalBeacons beacons={option.chain((a: AssetState) => a.lastLocalBeacons)(aso)} />
    </Stack>
  );
};
