import { NearMe, Sensors } from '@mui/icons-material';
import { PaperBox, SubHeader } from '../../../../Common/Sidebar';
import { truncationStyle } from '../../AssetRenderer';
import { Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { DeviceLocation, MicrofenceData } from '../../Messages';
import { useAtomValue } from 'jotai';
import { KNOWN_TOOLS } from '../../../../store/tools';
import { Dispatch } from 'react';
import { PortableAsset, PortableAssetTool } from '../../types';
import { Option, none, fromNullable } from 'fp-ts/es6/Option';
import { GridRowData } from '@material-ui/data-grid';
import { StrongFeatureHolder } from '../../../../hooks/geomoby/useLiveMapLoader';
import { Point } from 'ol/geom';
import { SelectedAsset } from '../../LiveMap';
import { MICROFENCE } from '../../BeaconUtils';

export const ToolSensedBy = ({
  lastLocation,
  microfences,
  activeAssets,
  setSelectedDevice,
  setSelectedTool,
  setSelectedMicrofence,
  setSelectedAsset,
}: {
  lastLocation: DeviceLocation | undefined;
  microfences: StrongFeatureHolder<Point, MicrofenceData>[];
  activeAssets: SelectedAsset[];
  setSelectedDevice: Dispatch<PortableAsset | undefined>;
  setSelectedMicrofence: Dispatch<GridRowData | undefined>;
  setSelectedTool: Dispatch<PortableAssetTool | undefined>;
  setSelectedAsset: Dispatch<Option<SelectedAsset>>;
}) => {
  const knownTools = useAtomValue(KNOWN_TOOLS);
  const foundTool = knownTools.find(tool => tool.id === lastLocation?.beaconId);
  const sensedBy = lastLocation?.source;
  const activeDevice = activeAssets.find(
    active =>
      active.prefix !== MICROFENCE && JSON.stringify(active.id) === JSON.stringify(sensedBy?.id),
  );
  const activeMicrofence = activeAssets.find(
    active =>
      active.prefix === MICROFENCE && JSON.stringify(active.id) === JSON.stringify(sensedBy?.id),
  );
  const foundMicrofence = microfences.find(
    microfence => JSON.stringify(microfence.data.assetId) === JSON.stringify(activeMicrofence?.id),
  );

  return (
    <PaperBox>
      <SubHeader icon={<Sensors />} timestamp={lastLocation?.timestamp}>
        Sensed By
      </SubHeader>

      {(foundTool || foundMicrofence) && sensedBy && (
        <div style={{ marginLeft: '1rem' }}>
          <Grid
            container
            direction="row"
            style={{
              display: 'grid',
              gridTemplateColumns: '90% 10%',
              width: '100%',
            }}
          >
            <Tooltip title={sensedBy.label}>
              <pre style={truncationStyle}>
                {foundMicrofence ? foundMicrofence.feature.get('type') : 'device'}: {sensedBy.label}
              </pre>
            </Tooltip>

            <Grid
              item
              style={{
                marginTop: '-7px',
                width: 'fit-content',
              }}
            >
              <Tooltip title={'NAVIGATE TO'}>
                <IconButton
                  color="primary"
                  onClick={() => {
                    if (foundMicrofence) {
                      setSelectedMicrofence(foundMicrofence.feature.getProperties());
                      setSelectedAsset(fromNullable(activeMicrofence));
                    } else {
                      setSelectedDevice({
                        id: sensedBy.id?.deviceId as string,
                        label: sensedBy.label ?? '',
                        type: 'device',
                      });
                      setSelectedAsset(fromNullable(activeDevice));
                    }
                    setSelectedTool(undefined);
                  }}
                >
                  <NearMe />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </div>
      )}

      {!((foundTool || foundMicrofence) && sensedBy) && <Typography>UNKOWN</Typography>}
    </PaperBox>
  );
};
